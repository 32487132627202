
export function GetUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function StripParams(queryString) {
    var resQueryString = queryString.split("?")[1];

    if (resQueryString) {
        resQueryString = RemoveParam(resQueryString, "index");
        resQueryString = RemoveParam(resQueryString, "topn");
        resQueryString = RemoveParam(resQueryString, "bottomn");
        resQueryString = RemoveParam(resQueryString, "shoesn");
        resQueryString = RemoveParam(resQueryString, "topi");
        resQueryString = RemoveParam(resQueryString, "bottomi");
        resQueryString = RemoveParam(resQueryString, "shoesi");
        resQueryString = RemoveParam(resQueryString, "tope");
        resQueryString = RemoveParam(resQueryString, "bottome");
        resQueryString = RemoveParam(resQueryString, "shoese");

        return "?"+resQueryString;
    }

    return "";
}

function RemoveParam(queryString, p) {
    var rtn = "";
    var params_arr = [];
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            var param = params_arr[i].split("=")[0];
            if (param === p) {
                params_arr.splice(i, 1);
            }
        }
        rtn = params_arr.join("&");
    }
    return rtn;
}

