import React, { Component } from 'react';
import '../Magazine.css';


export class ImportanceOfGoodCombination extends Component {	
	
render() {
    return (
      <div className="content-pane">	 
        <h1 id="ImportanceOfGoodCombination">Why It Is Important to Have the Right Combination in Every Outfit</h1>
        <p>The right selection of the outfit for every occasion, 
            whether formal or casual, plays a vital role in defining your personality traits. 
            No matter how smart and active you are, 
            society expects you to do the accurate pairing of your clothing items according to the norms and trends. 
            Or, you must have loved to buy all the nicest and trendiest clothes to hoard up your closet 
            but have no clue about how to create a sophisticated and amazing wardrobe with several accurately paired 
            clothing items and right styles or color combos! 
            You are struggling through the battle of how to create perfect combinations of outfit for various occasions. 
            This is an important phase of life and there are a few ways about how to build up wardrobe 
            according to your personality and lifestyle. 
            But here we will like to share all the important reasons to be conscious about creating perfect combos 
            of the clothing items to make stunning every day outfits.
        </p>
        <h2>Right Combinations of Outfits Complement Your Personality</h2>
        <p>No matter how many qualities, skills and abilities you own, 
            you won’t get chances or opportunities in life unless you practice them! 
            Same rule goes with the outfit styles. Your collection of nice, expensive and trendy clothes will not serve anything good 
            unless you wear them properly. The real purpose of a great outfit style is to complement you.
        </p>
        <h2>Symmetry and Harmony of Your Outfit Style Reflects Your Harmonized Mind</h2>
        <p>Clothing reflects your mind; the more sophisticated and elegant outfit you create by pairing the right kind of clothing items, 
            the more balanced personality you depict to others.
        </p>
        <h2>Dressing up According to the Occasions is a Dire Need</h2>
        <p>To survive in this modern world, you need to be updated and aware of the good combinations of outfits to be worn 
            according to every occasion; formal to casual. While attending a formal, 
            highly official meeting you are not expected to pair Denim with blazer and in the same way, 
            while going for a fun night party in casual manner you should not be wearing formal outfit style. 
            In short, You can exceed in this high tech modern life if you get dressed up according to the norms.
        </p>
        <p>Keeping in view of all the above points, you must never ignore the importance of carefully pairing 
            the right kind of clothing items and             
            <a href='/?p=mixmatch/shop/styleup_women'> color combinations </a>
            to create fabulous outfit ideas - 
            <b><a href='/'> Style up!</a></b>
        </p><br/><br/>
	  </div>
    );
  }
}