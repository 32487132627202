import React, { Component } from 'react';
import logo from '../pics/logo.png';
import { Link } from 'react-router-dom'
import './StyleupHeader.css';
import { FiltersDialog } from './FiltersDialog';
import { NavBar } from '../components/NavBar';
import { StripParams } from '../Utils/URLProccessUtil';
import Select from 'react-select';
import { convertArrayToObject, officialCollections } from '../Utils/CollectionData.js';

function staticProps() {}

const colourStyles = {
	control: 			styles => ({ ...styles, 
										//backgroundColor: 'white',
										minHeight: "15px"
									}),
	dropdownIndicator: 	base => 	({
										...base,
										color: "black",
										padding: 0
									}),
	indicatorSeparator: base => 	({
										...base,
										display: "none"
									}),
	valueContainer: 	base => 	({
										...base,
										paddingLeft: '2px'
									}),
	menu: 				base => 	({
										...base,
										marginTop: 10,
										boxShadow: "0px 5005px 0px 5000px rgba(0,0,0,0.2)"
									}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
	  return {
		...styles,
		textAlign: "left",
		color: (isSelected) ? "white" : "black" //"#dd6600"
	}}
  };

export class StyleupHeader extends Component {
	
	static collection = "";
	static type = "";

	constructor(props) {
		super(props);  

		staticProps.collection = "styleup_women";
		if (this.props.passedProps  !== undefined 
			&& this.props.passedProps.match.params.collection  !== undefined 
			&& this.props.passedProps.match.params.collection !== "") {
			staticProps.collection = this.props.passedProps.match.params.collection;
		}

		staticProps.type = "shop";
		if (this.props.passedProps  !== undefined 
			&& this.props.passedProps.match.params.type !== undefined 
			&& this.props.passedProps.match.params.type !== "") {
			staticProps.type = this.props.passedProps.match.params.type;
		}

		var stripedParams = this.props.passedProps !== undefined ? StripParams(this.props.passedProps.location.search) : "";
		if (stripedParams !== undefined && stripedParams !== "") {
			staticProps.searchParams = stripedParams;
		}

		var tmpPage = "";
		if (this.props.page) {
			tmpPage = this.props.page;
		}
		
		this.state = { 	showfilters: false, 
						page: tmpPage, 
						collection: staticProps.collection, 
						type: staticProps.type,
						searchParams: staticProps.searchParams}
		
		this.showFilters = this.showFilters.bind(this);
		this.onClose = this.onClose.bind(this);
		this.collectionSelected = this.collectionSelected.bind(this);
	}

	showFilters() {
		this.setState({displayfilters: "block"});
	} 

	onClose(){
		this.setState({displayfilters: "none"});
	}

	collectionSelected(event) {
		var page = window.location.pathname.split("/")[1];
		if (page === undefined || page === "") page = "mixmatch";

		if (event.value.includes("women")) {
			this.props.passedProps.genderChanged("women");
		} else {
			this.props.passedProps.genderChanged("men");
		}
		
		this.props.passedProps.history.push(`/${page}/${this.state.type}/${event.value}`);

		window.location.reload();
	}

  render() {	
	  var that = this;  
    return ( 
		<header className="App-header">		
				<div className="header-container">
					
					<NavBar type={this.state.type} 
							collection={this.state.collection}							
							searchParams={this.state.searchParams} 
							page={this.state.page}/>

					<Link to="/" className="Logo-name">
						<img src={logo} className="App-logo-static" alt="logo" />
						<h1 className="App-title">StyleUp</h1>
					</Link>				
					{/*<Link className="Gender-selection" to="/men" onClick={this.genderChangeToMen}
							style={{paddingRight: '10px', paddingLeft: '30px'}}>
							MEN
					</Link>
					<span className="Gender-selection">|</span>
					<Link className="Gender-selection" to="/women" onClick={this.genderChangeToWomen}
							style={{paddingLeft: '10px'}}>
							WOMEN
					</Link>*/}
					<FiltersDialog passedProps={this.props.passedProps} display={this.state.displayfilters} onClose={this.onClose} />
        	
					{/*<Link className="Header-link" to="/About">About</Link>*/}
					<div style={{visibility:  (that.state.page ==="allitems" || this.state.page === "mixandmatch") ? "visible" : "hidden"}}>
					<div className="filtersButton" onClick={() => this.showFilters()}><b>Filters</b></div>
					<i className="filterIcon fa" onClick={() => this.showFilters()}>&#xf0b0;</i>
					<b>
					<Select
							className="custom-select"
							classNamePrefix="select"
							defaultValue={convertArrayToObject(officialCollections, "value")[this.props.passedProps.match.params.collection]}
							//isDisabled={isDisabled}
							//isLoading={isLoading}
							onChange={(event) => this.collectionSelected(event)}
							isClearable={false}
							isRtl={false}
							isSearchable={false}
							options={officialCollections}
							styles={colourStyles}
							theme={theme => ({
								...theme,
								borderRadius: 0,
								colors: {
								...theme.colors,
								primary25: "#fff8f0",	//#fff2e5
								primary50: "#fff2e5",	//#ffcf9e
								primary75: "white",
								primary: "#dd6600",
								neutral0: "white",
								neutral5: "white",
								neutral10: "white",
								neutral20: "#dddddd",	//#e8e8e8
								neutral30: "#dd6600",	
								neutral40: "#dd6600",
								neutral50: "white",
								neutral60: "#dd6600",
								neutral70: "white",
								neutral80: "#dd6600"	//#dd6600
								}
							})}
						/>
					</b>
					{/*<div className="custom-select">						
						<select type="text" defaultValue={this.props.passedProps.match.params.collection}
									onChange={(event) => this.collectionSelected(event)}
									className="collectionSelect" >									
										<option value="styleup_women">StyleUp Women</option>							
										<option value="styleup-women-tucked-in">StyleUp Women - tucked in</option>	
						</select>
						<div className="select-selected"></div>
						<div className="select-items">									
										<div>StyleUp Women</div>							
										<div>StyleUp Women - tucked in</div>	
						</div>
						</div>*/}
						</div>

			</div>
		</header>
	); 
  }  
}