import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import Carousel from 'nuka-carousel';
import { GetUrlParameter } from '../Utils/URLProccessUtil';
import { HandleBuyClicks } from '../Utils/AnalyticsUtils';
//import { Link } from 'react-router-dom';
//import enlarge from '../pics/enlarge.svg';
import ebayIcon from '../pics/ebay-icon_64.png';
import amazonIcon from '../pics/Amazon-icon_64.png';
//import chevronLeft from '../pics/Ic_chevron_left.svg';
//import chevronRight from '../pics/Ic_chevron_right.svg';


export class CustomCarouselShoes extends Component {	
	constructor(props) {
        super(props);   
        
        this.direction = 1;
		
        this.state = { collection: this.props.collection,
                        slideIndex: this.props.localIndex,
                        height: "100",
                        brandIcon: ebayIcon,
                        transition: (GetUrlParameter("transition") === "fade") ? "fade" : "scroll",
                        brandVisibility: "visible",
                        zindex: this.props.zindex};
                        
        this.beforeSlide = this.beforeSlide.bind(this);
        this.afterSlide = this.afterSlide.bind(this);
        
    }
    
    componentWillMount() {			
        this.beforeSlide(this.props.localIndex-1);	
	}
	
	beforeSlide(slideIndex) {
        if(this.direction === 0) return;

        var nextIndex = 0;
        if ((slideIndex + this.direction) < 0) {nextIndex = this.props.collection.length-1;}
        else { nextIndex = (slideIndex + this.direction) % this.props.collection.length; }

        if (this.props.localIndex !== slideIndex) { //index was updated by outside component
            nextIndex = this.props.localIndex;
        }

		var currItem = this.state.collection[nextIndex];
        this.tmpheight = Math.round((currItem.bottom-currItem.top)*this.props.sizeProportion);
                
        this.tmpIcon = ebayIcon;
        if (currItem.Brand === "ebay") {
            this.tmpIcon = ebayIcon;
        } else if (currItem.Brand === "amazon") {            
            this.tmpIcon = amazonIcon;
        }

        this.tmpbrandVisibility = "visible";
        if (!currItem.BuyLink || currItem.BuyLink === "" || GetUrlParameter("showbrands") === "false") {
            this.tmpbrandVisibility = "hidden";
        }
		
		this.setState({ slideIndex: nextIndex, height: this.tmpheight, brandIcon: this.tmpIcon, brandVisibility: this.tmpbrandVisibility });
        this.props.afterSlide(nextIndex);
        this.direction = 0;
	}
    
    afterSlide(slideIndex) {        
        if (this.state.slideIndex !== slideIndex)  {
            var nextIndex = slideIndex;

            var currItem = this.state.collection[nextIndex];
            this.tmpheight = Math.round((currItem.bottom-currItem.top)*this.props.sizeProportion);
                    
            this.tmpIcon = ebayIcon;
            if (currItem.Brand === "ebay") {
                this.tmpIcon = ebayIcon;
            } else if (currItem.Brand === "amazon") {            
                this.tmpIcon = amazonIcon;
            }
    
            this.tmpbrandVisibility = "visible";
            if (!currItem.BuyLink || currItem.BuyLink === "" || GetUrlParameter("showbrands") === "false") {
                this.tmpbrandVisibility = "hidden";
            }
            
            this.setState({ slideIndex: nextIndex, height: this.tmpheight, brandIcon: this.tmpIcon, brandVisibility: this.tmpbrandVisibility });
            this.props.afterSlide(nextIndex);
        }        
    }
	
  render() {
    if (this.props.empty) {
      return (<div/>)
    }

    var that=this;
    return (
        <div className="Carousel-shoes" style={{position:"relative"}}>
            <Carousel wrapAround = {true} slideIndex={parseInt(this.props.localIndex)}  heightMode='current' renderBottomCenterControls={false} 
                                                transitionMode={this.state.transition}
                                                className="Carousel" beforeSlide={this.beforeSlide} speed={this.props.speed}
                                                style={{position: "absolute", zIndex: this.props.zindex, height: this.state.height, top: this.props.marginTop}}
                                                renderCenterLeftControls={({ previousSlide }) => (
                                                        <div onClick={() => {that.direction = -1; previousSlide();}} className="Nav-button-left" >
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="36" height="62" viewBox="0 0 36 62">
                                                                <path d="M22 10l-2 -2 -11 23 11 23 2 -2 -10 -21 z" fill="#aaa"/>
                                                            </svg>
                                                        </div>
                                                    )}
                                                    renderCenterRightControls={({ nextSlide }) => (
                                                        <div onClick={() => {that.direction = 1; nextSlide();}} className="Nav-button-right" >
                                                            <svg xmlns="https://www.w3.org/2000/svg" width="36" height="62" viewBox="0 0 36 62">
                                                                <path d="M14 8 l-2 2 10 21 -10 21 2 2 11 -23 z"  fill="#aaa"/>
                                                            </svg>
                                                        </div>
                                                    )}
                                                    >
                { 
            this.state.collection.map(function(listValue, index){
                let marginRight = listValue.marginSide2*that.props.sizeProportion*2.5;
                let marginLeft = -listValue.marginSide*that.props.sizeProportion*2.5; 
                return (
                    <div onClick={() => {that.props.bringToFront()}} key={listValue.timeStamp}>
                        <div style={{float: 'right', width: '50%', overflow: 'hidden'}}>
                            <LazyLoad height={Math.floor((listValue.bottom-listValue.top)*that.props.sizeProportion)} once overflow >
                            <img src={require(`../items/imgs/${that.props.collectionName}/pics${that.props.section}/${listValue.timeStamp}.png`)}
                                alt={listValue.Description} className="Carousel-item unselectable"  id={listValue.timeStamp} 
                                style={{float: 'right', marginRight: marginRight, width: '200%', height: Math.round((listValue.bottom-listValue.top)*that.props.sizeProportion),
                                filter: "brightness(" + (100 + listValue.brightness/2) + "%)", WebkitFilter: "brightness(" + (100 + listValue.brightness/2) + "%)" }}/>
                            </LazyLoad>
                        </div>
                        <div style={{float: 'left', width: '50%', overflow: 'hidden'}}>
                            <LazyLoad height={Math.floor((listValue.bottom-listValue.top)*that.props.sizeProportion)} once overflow >
                            <img src={require(`../items/imgs/${that.props.collectionName}/pics${that.props.section}/${listValue.timeStamp}.png`)}
                                alt={listValue.Description} className="Carousel-item unselectable" id={listValue.timeStamp+"2"} 
                                style={{marginLeft: marginLeft, width: '200%', height: Math.round((listValue.bottom-listValue.top)*that.props.sizeProportion),
                                filter: "brightness(" + (100 + listValue.brightness/2) + "%)", WebkitFilter: "brightness(" + (100 + listValue.brightness/2) + "%)" }}/>
                            </LazyLoad>
                        </div>
                    </div>
                    );
                })}
            </Carousel>
            {/*<div className="Bring-to-front" onClick={that.bringShoesToFront} style={{top: (this.state.marginTopShoes-15+this.state.shoesHeight/2), cursor: "pointer"}}>
                <img src={bringToFront} alt="bring clothing to front" />
                <span className="Details-span" >to front</span>
            </div>*/}
        <div className="Go-to-properties" onClick={() => {HandleBuyClicks(this.state.collection[this.state.slideIndex].BuyLink);}}
                    style={{top: (this.props.marginTop-15+this.state.height/2), 
                            visibility: this.state.brandVisibility, display: "inline-block",
                            borderRadius: "25%", padding: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.8)"}}>
            <a href={this.state.collection[this.state.slideIndex].BuyLink} target="_blank" 
                        rel="noopener noreferrer" style={{textDecoration: "none"}}>
                <span className="Details-span" style={{top: "-5px", paddingBottom:"5px", display: "block"}} >Buy on</span>
                <img alt="garment details" src={this.state.brandIcon} style={{width: "39px", height: "15px", objectFit: "contain", display: "block"}} />
                {/*<span className="Details-span">details</span>*/}
            </a>
        </div>
        </div>
    );
  }
}