import React, { Component } from 'react';
//import Img from 'react-image';
import './Collection.css';
//import styleup_women from '../items/styleup_women.json';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { SectionButton } from '../components/SectionButton';
import { StyleupHeader } from '../components/StyleupHeader';
import { Footer } from '../components/Footer';
import LazyLoad from 'react-lazyload';
import queryString from 'query-string';
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { ApplyFilters } from '../Utils/ItemsListUtils';
import { StripParams } from '../Utils/URLProccessUtil';
import {AppBackground} from '../components/AppBackground';
import { convertArrayToObject, allCollections } from '../Utils/CollectionData.js';
//import LazyLoad from 'react-lazy-load';
//import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

export class Collection extends Component {
	constructor(props) {
		super(props);  
		this.initialized = false; 

		SetPagePath(`/?p=all-clothes/${this.props.match.params.type}/${this.props.match.params.collection}`);

		if (this.props.selectedSection === 'top'){
			this.state = {	topSection_bg: "#dd6600", 
							bottomSection_bg: "LightGray", 
							shoesSection_bg: "LightGray", 
							selectedSection: "top"}
		}
		else if (this.props.selectedSection === 'bottom'){
			this.state = { 	topSection_bg: "LightGray", 
							bottomSection_bg: "#dd6600", 
							shoesSection_bg: "LightGray", 
							selectedSection: "bottom"}
		}
		else if (this.props.selectedSection === 'shoes'){
			this.state = { 	topSection_bg: "LightGray", 
							bottomSection_bg: "LightGray", 
							shoesSection_bg: "#dd6600", 
							selectedSection: "shoes" }
		}		
		
		this.topSectionSelected = this.topSectionSelected.bind(this);
		this.bottomSectionSelected = this.bottomSectionSelected.bind(this);
		this.shoesSectionSelected = this.shoesSectionSelected.bind(this);
		this.callSetSelectedItem = this.callSetSelectedItem.bind(this);
		this.genderChangeToMen = this.genderChangeToMen.bind(this);		
		this.genderChangeToWomen = this.genderChangeToWomen.bind(this);
		this.loadCollection = this.loadCollection.bind(this);
		this.MixInmixmatch = this.MixInmixmatch.bind(this);

		this.loadCollection();
	}

	async loadCollection() {
		this.workingCollection = await import(`../items/${this.props.match.params.collection + '.json'}`);
		var values = queryString.parse(this.props.location.search);

		if (values.brands) { this.brands = values.brands.split(",") } else {this.brands = []};
		if (values.season) { this.season = values.season.split(",") } else {this.season = []};
		if (values.occasion) { this.occasion = values.occasion.split(",") } else {this.occasion = []};
		if (values.topt) { this.topt = values.topt.split(",") } else {this.topt = []};
		if (values.bottomt) { this.bottomt = values.bottomt.split(",") } else {this.bottomt = []};
		if (values.shoest) { this.shoest = values.shoest.split(",") } else {this.shoest = []};
		
		this.workingCollectionTops = ApplyFilters(this.workingCollection.tops, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.topt});
		this.workingCollectionBottoms = ApplyFilters(this.workingCollection.bottoms, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.bottomt});
		this.workingCollectionShoes = ApplyFilters(this.workingCollection.shoes, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.shoest});

		this.currCollection = this.workingCollectionTops;
		if (this.props.selectedSection === "bottom")
		{
			this.currCollection = this.workingCollectionBottoms;
		}
		if (this.props.selectedSection === "shoes")
		{
			this.currCollection = this.workingCollectionShoes;
		}
		var gender = convertArrayToObject(allCollections, "value")[this.props.match.params.collection].gender;
		this.setState ({ selectedCollection:this.currCollection, gender: gender });
		this.initialized = true;
		this.forceUpdate();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	topSectionSelected() {	
		var tmpCollection = this.workingCollectionTops;
		/*if (this.props.gender === "men"){
			tmpCollection = MenTops;
		}*/
		this.setState({ topSection_bg: "#dd6600", 
						bottomSection_bg: "LightGray", 
						shoesSection_bg: "LightGray", 
						selectedSection: "top", 
						selectedCollection:tmpCollection});
		this.props.changeGridSection('top');
	}
	
	bottomSectionSelected() {	
		var tmpCollection = this.workingCollectionBottoms;
		/*if (this.props.gender === "men"){
			tmpCollection = MenBottoms;
		}*/	
		this.setState({ topSection_bg: "LightGray", 
						bottomSection_bg: "#dd6600", 
						shoesSection_bg: "LightGray", 
						selectedSection: "bottom", 
						selectedCollection:tmpCollection});	
		this.props.changeGridSection('bottom');
	}
	
	shoesSectionSelected() {
		var tmpCollection = this.workingCollectionShoes;
		/*if (this.props.gender === "men"){
			tmpCollection = MenShoes;
		}	*/
		this.setState({ topSection_bg: "LightGray", 
						bottomSection_bg: "LightGray", 
						shoesSection_bg: "#dd6600", 
						selectedSection: "shoes", 
						selectedCollection:tmpCollection});
		this.props.changeGridSection('shoes');
	}
	
	callSetSelectedItem(index) {
		this.props.setSelectedItem(index);
	}
	
	genderChangeToMen(e) {
		e.stopPropagation();
		this.props.genderChanged('men');
	}
	
	genderChangeToWomen(e) {
		e.stopPropagation();
		this.props.genderChanged('women');
	}

	MixInmixmatch(index) {
		if (this.state.selectedSection === "top")
		{
			this.props.topSelectedChanged(index);
		}
		else if (this.state.selectedSection === "bottom")
		{
			this.props.bottomSelectedChanged(index);
		}
		if (this.state.selectedSection === "shoes")
		{
			this.props.shoesSelectedChanged(index);
		}
	}
	
	
  render() {
		if (!this.initialized) return <div>
										<Helmet>
											<title>Clothes combination - outfit ideas for any garment | StyleUp</title>
											{/*<link rel="canonical" href={`https://styleupfashion.net/?p=all-clothes/${this.props.match.params.type}/${this.props.match.params.collection}`}></link>*/}
											<link rel="canonical" href="https://styleupfashion.net/?p=all-clothes/shops/styleup_women"></link>
											<meta name="description" content="Outfit ideas - clothing collection organized in a grid for quick browsing and selection. See any of the clothes in a full look with one click." />
										</Helmet>
										Loading ...
									</div>;

	  var that = this;
    return (
      <div className="App">	  
				<Helmet>
					<title>Clothes combination - outfit ideas for any garment | StyleUp</title>
					{/*<link rel="canonical" href={`https://styleupfashion.net/?p=all-clothes/${this.props.match.params.type}/${this.props.match.params.collection}`}></link>*/}
					<link rel="canonical" href="https://styleupfashion.net/?p=all-clothes/shops/styleup_women"></link>
					<meta name="description" content="Outfit ideas - clothing collection organized in a grid for quick browsing and selection. See any of the clothes in a full look with one click." />
				</Helmet>

				<AppBackground />

				<div className="App-container">
				
					<StyleupHeader passedProps={this.props} page="allitems"/>
					
					<div className="Body-wardrobe">
					
						<div className="fixedShelvesButtons">						
						<div className="Collection-shelves-panel">
							<SectionButton SectionID="top" gender={this.state.gender} handleClick={this.topSectionSelected} bg_Color={this.state.topSection_bg} />
							<SectionButton SectionID="bottom" gender={this.state.gender} handleClick={this.bottomSectionSelected} bg_Color={this.state.bottomSection_bg} />
							<SectionButton SectionID="shoes" gender={this.state.gender} handleClick={this.shoesSectionSelected} bg_Color={this.state.shoesSection_bg} />
						</div>
						</div>
					
						<div className="Grid">
							{ this.state.selectedCollection.map(function(listValue, indexId){
								return (
									<div className="Wardrobe-item">
										{/*<Link to={`/item-details/${that.props.match.params.type}/${that.props.match.params.collection}/${that.state.selectedSection}?index=${indexId}`} onClick = {() => {that.callSetSelectedItem(indexId)}}>
											<LazyLoad>
											<Img src={listValue.Path} alt={listValue.Description} className="Wardrobe-image" 
												loader={<div class="loader"></div>} />
											</LazyLoad>
										</Link>*/}
										<Link to={`/mixmatch/${that.props.match.params.type}/${that.props.match.params.collection}${StripParams(that.props.location.search)}`} 
												onClick = {() => {that.MixInmixmatch(indexId)}} rel="nofollow">
											<LazyLoad offset={3500}>
											<img src={require(`../items/imgs/${that.props.match.params.collection}/pics${that.state.selectedSection}/${listValue.timeStamp}.png`)} 
													alt={listValue.Description} className="Wardrobe-image" loader={<div class="loader"></div>} />
											
											</LazyLoad>
										</Link>										
									<div style={{position: "absolute", bottom: "0px", left: "-1px", width: "101%"}}>
										<Link className="grid-button" rel="nofollow"
												to={`/mixmatch/${that.props.match.params.type}/${that.props.match.params.collection}${StripParams(that.props.location.search)}`} 
												onClick = {() => {that.MixInmixmatch(indexId)}}
												>
											Mix it
										</Link>
										<Link className="grid-button" rel="nofollow"
												to={`/item-details/${that.props.match.params.type}/${that.props.match.params.collection}/${that.state.selectedSection}?index=${indexId}${that.props.location.search ? "&"+that.props.location.search.split("?")[1] : ""}`} 
												>
											Details
										</Link>
									</div>
									</div>
								);
							})}
						</div>

						{/*<div className="Links-to-properties" style={{marginTop: "50px"}}>
							{ this.state.selectedCollection.map(function(listValue, indexId){
								return (
										<Link to={`/item-details/${that.props.match.params.type}/${that.props.match.params.collection}/${that.state.selectedSection}?index=${indexId}${that.props.location.search ? "&"+that.props.location.search.split("?")[1] : ""}`} 
												style={{margin: "5px", display: "inline-block"}}>
											{indexId+1}
										</Link>
								);
							})}
						</div>*/}
					</div>
				</div>  	 
				<Footer />
      </div>
    );
  }
}
