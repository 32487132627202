import 'babel-polyfill';
import React, { Component } from 'react';
import ebayIcon from '../pics/ebay-icon_64.png';
import './App.css';
//import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {Helmet} from "react-helmet";
import { CustomCarousel } from '../components/CustomCarousel';
import { CustomCarouselShoes } from '../components/CustomCarouselShoes';
import { SectionButton } from '../components/SectionButton';
import { StyleupHeader } from '../components/StyleupHeader';
import { Footer } from '../components/Footer';
import LazyLoad from 'react-lazyload';
//import ShadowScrollbars from '../components/ShadowScrollbars';
import { CalcSizeProportion } from '../Utils/ResponsiveUtils';
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { AnalyticsTest } from '../Utils/AnalyticsUtils';
import { GetItemIndex, ApplyFilters } from '../Utils/ItemsListUtils';
import Img from 'react-image';
import { AppBackground } from '../components/AppBackground';
import { convertArrayToObject, allCollections } from '../Utils/CollectionData.js';
//import styleup_women from '../items/styleup_women.json';
//import styleup_women_tmp from '../items/all.json';
import { GetUrlParameter } from '../Utils/URLProccessUtil';
import wood1 from "../pics/person_bg/wood1.jpg"


class App extends Component {
	
	constructor(props) {
		super(props); 
		this.initialized = false; 

		var ua = window.navigator.userAgent;
		var edge = ua.indexOf('Edge/');
		if (parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) < 16) {
		  alert('Sorry, your browser is not supported');
		  this.props.history.push(`/about`);
		}
		
		if 	(this.props.match.params.type === undefined)	{
			this.props.match.params.type = "shop";
		}
		if 	(this.props.match.params.collection === undefined
			|| this.props.match.params.collection === "partners")	{
			this.props.match.params.collection = "styleup_women";
		}

		SetPagePath(`/?p=mixmatch/${this.props.match.params.type}/${this.props.match.params.collection}`);
		
		this.state = { 	topSection_bg: "#dd6600", bottomSection_bg: "Gray", shoesSection_bg: "Gray",
			topBrandIcon: ebayIcon, bottomBrandIcon: ebayIcon, shoesBrandIcon: ebayIcon,
			topsHeight: "100px", marginTopTop: 0, bottomsHeight: "100px", marginTopBottom: 0,
			shoesHeight: "100px", marginTopShoes: 0,
			topSection_bg: "#dd6600", bottomSection_bg: "LightGray", shoesSection_bg: "LightGray", selectedSection: "top",
			zindexTop: '4', zindexBottom: '3', zindexShoes: '2', speed: 0
		 };	
		
		this.topSectionSelected = this.topSectionSelected.bind(this);
		this.bottomSectionSelected = this.bottomSectionSelected.bind(this);
		this.shoesSectionSelected = this.shoesSectionSelected.bind(this);
		this.afterSlideTop = this.afterSlideTop.bind(this);
		this.afterSlideBottom = this.afterSlideBottom.bind(this);
		this.afterSlideShoes = this.afterSlideShoes.bind(this);
		this.onMount = this.onMount.bind(this);			
		this.genderChangeToMen = this.genderChangeToMen.bind(this);		
		this.genderChangeToWomen = this.genderChangeToWomen.bind(this);	
		this.callSetSelectedItem = this.callSetSelectedItem.bind(this);	
		this.bringShoesToFront = this.bringShoesToFront.bind(this);
		this.bringBottomToFront = this.bringBottomToFront.bind(this);
		this.bringTopToFront = this.bringTopToFront.bind(this);
		this.itemClicked = this.itemClicked.bind(this);
		//this.shareImage = this.shareImage.bind(this);
		this.loadCollection = this.loadCollection.bind(this);
		
		window.addEventListener("resize", this.onMount);
	}

	async loadCollection() {

		this.bottomZIndex = this.props.match.params.collection.includes("tucked") ? 5 : 3;
		var gender = convertArrayToObject(allCollections, "value")[this.props.match.params.collection].gender;

		this.tmpCollection = await import(`../items/${this.props.match.params.collection + '.json'}`);

		var values = queryString.parse(this.props.location.search);
		this.tope = values.tope;
		this.bottome = values.bottome;
		this.shoese = values.shoese;

		if (values.topi) { this.topi = values.topi } else {this.topi = this.props.indexTop};
		if (values.bottomi) { this.bottomi = values.bottomi } else {this.bottomi = this.props.indexBottom};
		if (values.shoesi) { this.shoesi = values.shoesi } else {this.shoesi = this.props.indexShoes};

		if (values.topn) { this.topi = GetItemIndex(this.tmpCollection.tops, values.topn) };
		if (values.bottomn) { this.bottomi = GetItemIndex(this.tmpCollection.bottoms, values.bottomn) };
		if (values.shoesn) { this.shoesi = GetItemIndex(this.tmpCollection.shoes, values.shoesn) };

		if (values.brands) { this.brands = values.brands.split(",") } else {this.brands = []};
		if (values.season) { this.season = values.season.split(",") } else {this.season = []};
		if (values.occasion) { this.occasion = values.occasion.split(",") } else {this.occasion = []};
		if (values.topt) { this.topt = values.topt.split(",") } else {this.topt = []};
		if (values.bottomt) { this.bottomt = values.bottomt.split(",") } else {this.bottomt = []};
		if (values.shoest) { this.shoest = values.shoest.split(",") } else {this.shoest = []};
		
		this.workingCollectionTops = ApplyFilters(this.tmpCollection.tops, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.topt});
		this.workingCollectionBottoms = ApplyFilters(this.tmpCollection.bottoms, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.bottomt});
		this.workingCollectionShoes = ApplyFilters(this.tmpCollection.shoes, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.shoest});

		this.tope = this.tope || this.workingCollectionTops[0].timeStamp === "000";
		this.bottome = this.bottome || this.workingCollectionBottoms[0].timeStamp === "000";
		this.shoese = this.shoese || this.workingCollectionShoes[0].timeStamp === "000";

		this.setState ({ 	localIndexTop: this.topi, slideIndexTop: this.topi,
							localIndexBottom: this.bottomi, slideIndexBottom: this.bottomi,
							localIndexShoes: this.shoesi, slideIndexShoes: this.shoesi,
							selectedCollectionTop: this.workingCollectionTops, 
							selectedCollectionBottom: this.workingCollectionBottoms, 
							selectedCollectionShoes: this.workingCollectionShoes,
							selectedCollection: this.workingCollectionTops,
							gender: gender
		 });

		this.initialized = true;
		this.onMount();
		this.forceUpdate();
	}
	
	componentWillMount() {
		this.loadCollection();			
		if (this.initialized) this.onMount();	
	}
	
	componentDidMount() {		
		//if (this.initialized) this.onMount();	
		window.scrollTo(0, 0);
	}
	
	onMount() {
		this.sizeProportion = CalcSizeProportion();
		
		this.tmpShoesHeight = (this.state.selectedCollectionShoes[this.state.slideIndexShoes].bottom-this.state.selectedCollectionShoes[this.state.slideIndexShoes].top)*this.sizeProportion;
		this.tmpMarginTopShoes = (this.state.selectedCollectionShoes[this.state.slideIndexShoes].top*this.sizeProportion)+this.state.selectedCollectionShoes[this.state.slideIndexShoes].marginTopAddition*this.sizeProportion*5;
		this.tmpBottomHeight = (this.state.selectedCollectionBottom[this.state.slideIndexBottom].bottom-this.state.selectedCollectionBottom[this.state.slideIndexBottom].top)*this.sizeProportion;
		this.tmpMarginTopBottom = this.state.selectedCollectionBottom[this.state.slideIndexBottom].top*this.sizeProportion+this.state.selectedCollectionBottom[this.state.slideIndexBottom].marginTopAddition*this.sizeProportion*5;
		this.tmpTopHeight = (this.state.selectedCollectionTop[this.state.slideIndexTop].bottom-this.state.selectedCollectionTop[this.state.slideIndexTop].top)*this.sizeProportion;
		this.tmpMarginTopTop = this.state.selectedCollectionTop[this.state.slideIndexTop].top*this.sizeProportion+this.state.selectedCollectionTop[this.state.slideIndexTop].marginTopAddition*this.sizeProportion*5;

		this.tmpShoesHeight = Math.round(this.tmpShoesHeight);
		this.tmpMarginTopShoes = Math.round(this.tmpMarginTopShoes);
		this.tmpBottomHeight = Math.round(this.tmpBottomHeight);
		this.tmpMarginTopBottom = Math.round(this.tmpMarginTopBottom);
		this.tmpTopHeight = Math.round(this.tmpTopHeight);
		this.tmpMarginTopTop = Math.round(this.tmpMarginTopTop);
		
		var bottomGap = Math.ceil(this.tmpMarginTopBottom - this.tmpTopHeight - this.tmpMarginTopTop);
		if (bottomGap > 0) {
			this.tmpMarginTopBottom = this.tmpMarginTopBottom - bottomGap;
		}
		var shoesGap = Math.ceil(this.tmpMarginTopShoes - this.tmpBottomHeight - this.tmpMarginTopBottom);
		if (shoesGap > 0) {
			this.tmpMarginTopShoes = this.tmpMarginTopShoes - shoesGap;
		}
		
		this.setState({
				shoesHeight: this.tmpShoesHeight,				
				marginTopShoes: this.tmpMarginTopShoes,				
				bottomsHeight: this.tmpBottomHeight,				
				marginTopBottom: this.tmpMarginTopBottom,				
				topsHeight: this.tmpTopHeight,				
				marginTopTop: this.tmpMarginTopTop
			});	
	}
	
	topSectionSelected() {	
		var tmpCollection = this.workingCollectionTops;
		/*if (this.props.gender === "men"){
			tmpCollection = MenTops;
		}*/
		this.setState({ topSection_bg: "#dd6600", bottomSection_bg: "LightGray", shoesSection_bg: "LightGray", selectedSection: "top", selectedCollection:tmpCollection })	
	}
	
	bottomSectionSelected() {	
		var tmpCollection = this.workingCollectionBottoms;
		/*if (this.props.gender === "men"){
			tmpCollection = MenBottoms;
		}*/	
		this.setState({ topSection_bg: "LightGray", bottomSection_bg: "#dd6600", shoesSection_bg: "LightGray", selectedSection: "bottom", selectedCollection:tmpCollection })	
	}
	
	shoesSectionSelected() {	
		var tmpCollection = this.workingCollectionShoes;
		/*if (this.props.gender === "men"){
			tmpCollection = MenShoes;
		}*/
		this.setState({ topSection_bg: "LightGray", bottomSection_bg: "LightGray", shoesSection_bg: "#dd6600", selectedSection: "shoes", selectedCollection:tmpCollection })	
	}
	
	itemClicked(index) {
		//AnalyticsTest();
		this.setState({speed: "0"});
		if (this.state.selectedSection === "top") {
			this.setState({localIndexTop: index});
		} else if (this.state.selectedSection === "bottom") {
			this.setState({localIndexBottom: index});
		} else if (this.state.selectedSection === "shoes") {
			this.setState({localIndexShoes: index});
		}
	}
	
	afterSlideTop(slideIndex) {
		var currItem = this.state.selectedCollectionTop[slideIndex];	
		this.tmpTopHeight = Math.round((currItem.bottom-currItem.top)*this.sizeProportion);
		this.tmpMarginTopTop = Math.round(currItem.top * this.sizeProportion + currItem.marginTopAddition*this.sizeProportion*5);
		
		this.setState({
			localIndexTop: slideIndex,
			slideIndexTop: slideIndex,
			topsHeight: this.tmpTopHeight,	
			marginTopTop: this.tmpMarginTopTop
		});	
		
		this.props.topSelectedChanged(slideIndex);
		this.afterSlideBottom(this.state.slideIndexBottom);
	}
	
	afterSlideBottom(slideIndex) {
		var currItem = this.state.selectedCollectionBottom[slideIndex];
		this.tmpBottomHeight = Math.round((currItem.bottom-currItem.top)*this.sizeProportion);
		this.tmpMarginTopBottom = Math.round(currItem.top*this.sizeProportion+currItem.marginTopAddition*this.sizeProportion*5);
		
		var gap = Math.ceil(this.tmpMarginTopBottom - this.tmpTopHeight - this.tmpMarginTopTop);
		if (gap > 0) {
			this.tmpMarginTopBottom = this.tmpMarginTopBottom - gap;
		}
		//alert(this.tmpMarginTopBottom);
		
		this.setState({
				localIndexBottom: slideIndex,
				slideIndexBottom: slideIndex,
				bottomsHeight: this.tmpBottomHeight,
				marginTopBottom: this.tmpMarginTopBottom,
				zindexTop: 1 + parseFloat(this.state.zindexBottom)
			});
		this.props.bottomSelectedChanged(slideIndex);
		this.afterSlideShoes(this.state.slideIndexShoes);
	}
	
	afterSlideShoes(slideIndex) {
		var currItem = this.state.selectedCollectionShoes[slideIndex];
		this.tmpShoesHeight = Math.round((currItem.bottom-currItem.top)*this.sizeProportion);
		this.tmpMarginTopShoes = Math.round(currItem.top*this.sizeProportion+currItem.marginTopAddition*this.sizeProportion*5);
		
		var gapShoes = Math.ceil(this.tmpMarginTopShoes - this.tmpBottomHeight - this.tmpMarginTopBottom);
		if (gapShoes > 0) {
			this.tmpMarginTopShoes = this.tmpMarginTopShoes - gapShoes;
		}
		
		this.setState({
				localIndexShoes: slideIndex,
				slideIndexShoes: slideIndex,
				shoesHeight: this.tmpShoesHeight,
				marginTopShoes: this.tmpMarginTopShoes,
				zindexTop: '4', zindexBottom: this.bottomZIndex, zindexShoes: '2'
			});
		this.props.shoesSelectedChanged(slideIndex);
		
		this.setState({speed: "300"});
	}
	
	bringShoesToFront() {
		this.setState({zindexShoes: 1 + parseFloat(this.state.zindexBottom)});
	}
	
	bringBottomToFront(e) {
		this.setState({zindexBottom: 1 + parseFloat(this.state.zindexTop) + parseFloat(this.state.zindexShoes)});
	}	
	
	bringTopToFront(e) {
		this.setState({zindexTop: 1 + parseFloat(this.state.zindexBottom)});
	}
	
	genderChangeToMen(e) {
		e.stopPropagation();
		this.props.genderChanged('men');
		//this.setState({selectedCollectionTop: MenTops, selectedCollectionBottom: MenBottoms, selectedCollectionShoes: MenShoes});
	}
	
	genderChangeToWomen(e) {
		e.stopPropagation();
		this.props.genderChanged('women');
		this.setState({selectedCollectionTop: this.workingCollectionTops, selectedCollectionBottom: this.workingCollectionBottoms, selectedCollectionShoes: this.workingCollectionShoes});
	}
	
	callSetSelectedItem(index) {
		this.props.setSelectedItem(index);
	}
	
  render() {	  
		if (!this.initialized) return 	<div>
											<Helmet>
												<title>Create your look and get inspired with the Mix and Match clothes combination | StyleUp</title>
												{/*<link rel="canonical" href="https://styleupfashion.net"></link>*/}
												{/*<link rel="canonical" href={`https://styleupfashion.net/?p=mixmatch/${this.props.match.params.type}/${this.props.match.params.collection}`}></link>*/}
												<link rel="canonical" href="https://styleupfashion.net/?p=mixmatch/shops/styleup_women"></link>
												<meta name="description" content="Create the total look by combining the top, bottom and shoes. See what outfit you like the best or which garment fits in all combinations. Get outfit ideas and style up!" />
											</Helmet>
											Loading ...
										</div>;

		var that = this;
    return ( 
      <div className="App">
				<Helmet>
					<title>Create your look and get inspired with the Mix and Match clothes combination | StyleUp</title>
					{/*<link rel="canonical" href="https://styleupfashion.net"></link>*/}
					{/*<link rel="canonical" href={`https://styleupfashion.net/?p=mixmatch/${this.props.match.params.type}/${this.props.match.params.collection}`}></link>*/}
					<link rel="canonical" href="https://styleupfashion.net/?p=mixmatch/shops/styleup_women"></link>
					<meta name="description" content="Create the total look by combining the top, bottom and shoes. See what outfit you like the best or which garment fits in all combinations. Get outfit ideas and style up!" />
				</Helmet>

				<AppBackground />
	
				<div className="App-container">
					<StyleupHeader passedProps={this.props} page="mixandmatch"/>

					<div className="Body-container">
						<div className={`Body 
						/*${queryString.parse(this.props.location.search).bg}*/
						`} style={{marginTop:`calc(48vh - ${that.sizeProportion*500 + 75}px)`}}>
							
							<div className={"Carousels-pane unselectable"} id="divToShare" >	

								<CustomCarouselShoes type={this.props.match.params.type} 
												speed={this.state.speed}
												section="shoes"
												empty={this.shoese}
												sizeProportion={this.sizeProportion}
												marginTop={this.state.marginTopShoes}
												localIndex={this.state.localIndexShoes}
												zindex={this.state.zindexShoes}
												collection={this.state.selectedCollectionShoes}
												collectionName={this.props.match.params.collection}
												bringToFront={this.bringShoesToFront}
												callSetSelectedItem={this.callSetSelectedItem}
												afterSlide={this.afterSlideShoes} />
												
								<CustomCarousel type={this.props.match.params.type} 
												speed={this.state.speed}
												section="bottom"
												empty={this.bottome}
												sizeProportion={this.sizeProportion}
												marginTop={this.state.marginTopBottom}
												localIndex={this.state.localIndexBottom}
												zindex={this.state.zindexBottom}
												collection={this.state.selectedCollectionBottom}
												collectionName={this.props.match.params.collection}
												bringToFront={this.bringBottomToFront}
												callSetSelectedItem={this.callSetSelectedItem}
												afterSlide={this.afterSlideBottom} />
												
								<CustomCarousel type={this.props.match.params.type} 
												speed={this.state.speed}
												section="top"
												empty={this.tope}
												sizeProportion={this.sizeProportion}
												marginTop={this.state.marginTopTop}
												localIndex={this.state.localIndexTop}
												zindex={this.state.zindexTop}
												collection={this.state.selectedCollectionTop}
												collectionName={this.props.match.params.collection}
												bringToFront={this.bringTopToFront}
												callSetSelectedItem={this.callSetSelectedItem}
												afterSlide={this.afterSlideTop} />
												
							</div>	
							<div className={`background ${queryString.parse(this.props.location.search).bg}`}>
										{/*<img src={bgimg} style={{height: "100%", borderRadius: "1%", 
																width: "100%", objectFit: "fit", opacity: "1"}}/>*/}
							</div>					
						</div>
						
						<div className="Items-container" >
							<div style={{width:"600px"}}>
							<div style={{display: "block"}}>
								<div className="Shelves-panel">
								<SectionButton SectionID="top" gender={this.state.gender} handleClick={this.topSectionSelected} bg_Color={this.state.topSection_bg} />
								<SectionButton SectionID="bottom" gender={this.state.gender} handleClick={this.bottomSectionSelected} bg_Color={this.state.bottomSection_bg} />
								<SectionButton SectionID="shoes" gender={this.state.gender} handleClick={this.shoesSectionSelected} bg_Color={this.state.shoesSection_bg} />
								</div>
							</div>
							</div>
					
							{/*<ShadowScrollbars style={{ height: "1000px" }}>*/}
								<div className="Items-grid">
										{ this.state.selectedCollection.map(function(listValue, indexId){
											return (
												<div className="Grid-item">
														<LazyLoad once overflow scrollContainer="Items-grid" offset={500} key={that.state.selectedSection}>
														<Img src={require(`../items/imgs/${that.props.match.params.collection}/pics${that.state.selectedSection}/${listValue.timeStamp}.png`)} 
																alt={listValue.Description} className="Grid-item-image" 
																onClick = {() => {that.itemClicked(indexId)}} key={listValue.timeStamp}
																loader={<div className="loader"></div>} />
														</LazyLoad>
												</div>
											);
										})}
								</div>
							{/*</ShadowScrollbars>*/}
						</div>
						
					</div>
				</div>  
				<Footer />
      </div>
    );
  }
}

export default App;
