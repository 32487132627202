import React, { Component } from 'react';
import './SectionButton.css';
import lady_up from '../pics/lady_up.png';
import lady_buttom from '../pics/lady_buttom.png';
import shoes_women from '../pics/shoes_women.png';
import man_top from '../pics/man_top.png';
import man_buttom from '../pics/man_buttom.png';
import shoes_men from '../pics/shoes_men.png';


export class SectionButton extends Component {	
	constructor(props) {
		super(props);   
		//alert(this.props.gender);
		if (this.props.gender === "men") {
			if (this.props.SectionID === "top")
			{
				this.img = man_top
			}
			else if (this.props.SectionID === "bottom")
			{
				this.img = man_buttom
			}
			if (this.props.SectionID === "shoes")
			{
				this.img = shoes_men
			}
		}
		else {
			if (this.props.SectionID === "top")
			{
				this.img = lady_up
			}
			else if (this.props.SectionID === "bottom")
			{
				this.img = lady_buttom
			}
			if (this.props.SectionID === "shoes")
			{
				this.img = shoes_women
			}
		}
	}
	
  render() {
    return (
      <div className="Section-button" style={{borderColor: this.props.bg_Color}} onClick={this.props.handleClick} >
	    <img src={this.img} className="Section-image-button" alt="section img"  />
	  </div>
    );
  }
}