import React, { Component } from 'react';
import { NavBar } from '../components/NavBar';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import queryString from 'query-string';
//import styleup_women from '../items/styleup_women.json';
import './ItemProperties.css';
import Carousel from 'nuka-carousel';
import { StyleupHeader } from '../components/StyleupHeader';
import { Footer } from '../components/Footer';
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { ApplyFilters } from '../Utils/ItemsListUtils';
import { StripParams } from '../Utils/URLProccessUtil';
import {AppBackground} from '../components/AppBackground';


export class ItemProperties extends Component {
	
	constructor(props) {
		super(props);   
		this.initialized = false; 

		SetPagePath(`/?p=item-details/${this.props.match.params.type}/${this.props.match.params.collection}/${this.props.match.params.section}`);
		
		var values = queryString.parse(this.props.location.search);
		this.initIndex = values.index;
		if (!this.initIndex) this.initIndex = 0;
		this.state = { /*selectedCollection: this.currCollection,*/ selectedIndex: this.initIndex, speed: "0", cursor: "pointer"};
		
		this.MixInmixmatch = this.MixInmixmatch.bind(this);	
		this.afterSlide = this.afterSlide.bind(this);
		this.genderChangeToMen = this.genderChangeToMen.bind(this);		
		this.genderChangeToWomen = this.genderChangeToWomen.bind(this);	
		this.handleClick = this.handleClick.bind(this);		
		this.loadCollection = this.loadCollection.bind(this);

		this.loadCollection();
	}

	async loadCollection() {
		this.workingCollection = await import(`../items/${this.props.match.params.collection + '.json'}`);
		var values = queryString.parse(this.props.location.search);

		if (values.brands) { this.brands = values.brands.split(",") } else {this.brands = []};
		if (values.season) { this.season = values.season.split(",") } else {this.season = []};
		if (values.occasion) { this.occasion = values.occasion.split(",") } else {this.occasion = []};
		if (values.topt) { this.topt = values.topt.split(",") } else {this.topt = []};
		if (values.bottomt) { this.bottomt = values.bottomt.split(",") } else {this.bottomt = []};
		if (values.shoest) { this.shoest = values.shoest.split(",") } else {this.shoest = []};
		
		this.workingCollectionTops = ApplyFilters(this.workingCollection.tops, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.topt});
		this.workingCollectionBottoms = ApplyFilters(this.workingCollection.bottoms, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.bottomt});
		this.workingCollectionShoes = ApplyFilters(this.workingCollection.shoes, {brands: this.brands, season: this.season, occasion: this.occasion, types: this.shoest});

		this.currCollection = this.workingCollectionTops;
		if (this.props.match.params.section === "bottom")
		{
			this.currCollection = this.workingCollectionBottoms;
		}
		if (this.props.match.params.section === "shoes")
		{
			this.currCollection = this.workingCollectionShoes;
		}
		this.setState ({ selectedCollection:this.currCollection });
		
		this.initialized = true;
		this.forceUpdate();
		this.componentWillMount();
		this.componentDidMount();
	}
	
	componentWillMount() {			
		this.setState({speed: "0"});		
	}
	
	componentDidMount() { 
		if (this.initialized) this.afterSlide(this.initIndex);
		window.scrollTo(0, 0);
		setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 0); 
		setTimeout(() => { this.setState({speed: "300"}); }, 0);
	}	
	
	afterSlide(slideIndex) {
		var currItemLink = this.state.selectedCollection[slideIndex].BuyLink;
		var buttonText = "BUY IT!";
		var bgColor = "#dd6600";
		var btnCursor = "pointer";
		if (currItemLink === undefined || currItemLink === "") {
			buttonText = "SOLD OUT";
			bgColor = "#999";
			currItemLink = "";
			btnCursor = "context-menu";
		}
		this.setState({selectedIndex: slideIndex, buttonText: buttonText, buttonBg: bgColor, buylink: currItemLink, cursor: btnCursor});
	}
	
	MixInmixmatch() {
		if (this.props.match.params.section === "top")
		{
			this.props.topSelectedChanged(this.state.selectedIndex);
		}
		else if (this.props.match.params.section === "bottom")
		{
			this.props.bottomSelectedChanged(this.state.selectedIndex);
		}
		if (this.props.match.params.section === "shoes")
		{
			this.props.shoesSelectedChanged(this.state.selectedIndex);
		}
	}
	
	handleClick(e) {
		if(this.state.buylink === "") {
			e.preventDefault();
		}
	}
	
	genderChangeToMen(e) {
		e.stopPropagation();
		this.props.genderChanged('men');
	}
	
	genderChangeToWomen(e) {
		e.stopPropagation();
		this.props.genderChanged('women');
	}
	
  render() {
	var that = this;

	if (!this.initialized) return <div>
									<Helmet>
										<title>Clothing Details - StyleUp</title>
										<meta name="robots" content="noindex"></meta>
										{/*<link rel="canonical" href={`https://styleupfashion.net/?p=item-details/${that.props.match.params.type}/${that.props.match.params.collection}/${that.state.selectedSection}?index=${that.initIndex}`}></link>*/}
										<meta name="description" content="Showing enlarged image of a fashion garment with detailed description including size, color, fabric, and style features like sleeves length or waist height" />
									</Helmet>
									Loading ...
								</div>;

    return (
      <div className="App">	  
				<div className="App-container">
					<Helmet>
						<title>Clothing Details - StyleUp</title>
						<meta name="robots" content="noindex"></meta>
						{/*<link rel="canonical" href={`https://styleupfashion.net/?p=item-details/${that.props.match.params.type}/${that.props.match.params.collection}/${that.state.selectedSection}?index=${that.initIndex}`}></link>*/}
						<meta name="description" content="Showing enlarged image of a fashion garment with detailed description including size, color, fabric, and style features like sleeves length or waist height" />
					</Helmet>

					<AppBackground />
				
					<StyleupHeader passedProps={this.props}/>

					<NavBar type={this.props.match.params.type} 
							collection={this.props.match.params.collection}
							searchParams={StripParams(this.props.location.search)} />
					
					<div className="Body-item-properties">
					
						<div >
							<Carousel wrapAround = {true} slideIndex={parseInt(this.initIndex)} heightMode='current' renderBottomCenterControls={false} speed={this.state.speed}
																		className="Item-carousel" initialSlideHeight='600px' afterSlide={this.afterSlide}
																		renderCenterLeftControls={({ previousSlide }) => (
																			<button onClick={previousSlide} className="Nav-button">
																				<i className="fa fa-angle-left fa-4x" aria-hidden="true"></i>
																			</button>
																			)}
																			renderCenterRightControls={({ nextSlide }) => (
																			<button onClick={nextSlide} className="Nav-button">
																				<i className="fa fa-angle-right fa-4x" aria-hidden="true"></i>
																			</button>
																			)}
																		>
										{ 
									that.state.selectedCollection.map(function(listValue){
										return (									
												<img src={require(`../items/imgs/${that.props.match.params.collection}/pics${that.props.match.params.section}/${listValue.timeStamp}.png`)} alt={listValue.Description} className="Item-image"/>
											);
										})}
							</Carousel>
						</div>
						<div className="Properties-buttons" >
								<Link to={`/mixmatch/${this.props.match.params.type}/${this.props.match.params.collection}${StripParams(that.props.location.search)}`} onClick={that.MixInmixmatch} className="Properties-button" rel="nofollow">	
									MIX IT
								</Link>
								<a href={this.state.buylink} onClick={this.handleClick} target="_blank" rel="noopener noreferrer" className="Properties-button">
									{this.state.buttonText}
								</a>
						</div>
						<p className="Item-description" >{that.state.selectedCollection[that.state.selectedIndex].Description}</p>
					</div>
			
				</div>
				<Footer />
      </div>
    );
  }
}