import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import registerServiceWorker from './registerServiceWorker'
//import { HashRouter, Route, Redirect, Switch } from 'react-router-dom'
//import { BrowserRouter as Router, Route } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './pages/App'
import { Collection } from './pages/Collection.js'
import { ItemProperties } from './pages/ItemProperties.js'
import { About } from './pages/About.js'
import { Welcome } from './pages/Welcome.js'
import { GithubRedirect } from './pages/GithubRedirect.js'
import { Nomatch } from './pages/Nomatch.js'
import { PrivacyPolicy } from './pages/PrivacyPolicy.js'
import { Magazine } from './pages/Magazine.js'
import { Admin } from './pages/Admin.js'


var indexTop = 0;
function topSelectedChanged(index) {
	indexTop = index;
}

var indexBottom = 0;
function bottomSelectedChanged(index) {
	indexBottom = index;
}

var indexShoes = 0;
function shoesSelectedChanged(index) {
	indexShoes = index;
}

var selectedIndex = 0;
function setSelectedItem(index) {
	selectedIndex = index;
}

var gridSection = 'top';
function setGridSection(section) {
	gridSection = section;
}

//gender values to consts
var gender;
function genderChanged(g) {
	if (gender === undefined) gender = 'women';
	gender = g;
	indexTop = 0;
	indexBottom = 0;
	indexShoes = 0;
	selectedIndex = 0;
	gridSection = 'top';
}

ReactDOM.render(
	<Router >
		<Switch >
		<Route exact path="/" 								render={(props) => <Welcome {...props} genderChanged={genderChanged} />}/>
		{/*<Route exact path="/" render={(props) => <GithubRedirect {...props} />}/>*/}
	  
		{/*<Route exact path="/" 								render={(props) => <App {...props}
																	indexTop={indexTop} indexBottom={indexBottom} indexShoes={indexShoes} 
																	gender={gender} setSelectedItem={setSelectedItem} 
																	topSelectedChanged={topSelectedChanged} bottomSelectedChanged={bottomSelectedChanged} shoesSelectedChanged={shoesSelectedChanged}
genderChanged={genderChanged}/>} />*/}

		<Route exact path="/mixmatch/:type/:collection" 	render={(props) => <App {...props}
																	indexTop={indexTop} indexBottom={indexBottom} indexShoes={indexShoes} 
																	gender={gender} setSelectedItem={setSelectedItem} 
																	topSelectedChanged={topSelectedChanged} bottomSelectedChanged={bottomSelectedChanged} shoesSelectedChanged={shoesSelectedChanged}
																	genderChanged={genderChanged}/>} />
		{/*<Route exact path="/men" render={() => (<Redirect to="/mixmatch" />)}/>
		<Route exact path="/women" render={() => (<Redirect to="/mixmatch" />)}/>*/}
																	
		<Route exact path="/all-clothes/:type/:collection" render={(props) => <Collection {...props} 
																	gender={gender} setSelectedItem={setSelectedItem} 
																	topSelectedChanged={topSelectedChanged}
																	bottomSelectedChanged={bottomSelectedChanged}
																	shoesSelectedChanged={shoesSelectedChanged}			
																	changeGridSection={setGridSection}
																	selectedSection={gridSection}
																	genderChanged={genderChanged}/>}/>		
		
		<Route exact path="/item-details/:type/:collection/:section" render={(props) => <ItemProperties {...props} gender={gender}
																	topSelectedChanged={topSelectedChanged}
																	bottomSelectedChanged={bottomSelectedChanged}
																	shoesSelectedChanged={shoesSelectedChanged}
																	genderChanged={genderChanged}/>}/>
																		
		<Route exact path="/about" render={(props) => <About {...props} genderChanged={genderChanged}/>}/>
		<Route exact path="/PrivacyPolicy" render={(props) => <PrivacyPolicy {...props} />}/>
		<Route exact path="/blog/:topic?" render={(props) => <Magazine {...props} />}/>
		<Route exact path="/admin/:collection" render={(props) => <Admin {...props} />}/>
		<Route path="*" render={() => <Nomatch/>}/>
		</Switch>
	</Router>, 
	document.getElementById('root')
);


registerServiceWorker();
