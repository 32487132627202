


export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
}

export const officialCollections = [
	{ value: 'styleup-men', label: 'styleup men', gender: 'men' },
	{ value: 'styleup_women', label: 'styleup women', gender: 'women' }
]

export const allCollections = [
	{ value: 'styleup-men', label: 'styleup men', gender: 'men' },
	{ value: 'styleup_women', label: 'styleup women', gender: 'women' },
	{ value: 'styleup-women-tucked-in', label: 'styleup women tucked in', gender: 'women' },
	{ value: 'select', label: 'select', gender: 'women' },
	{ value: 'crazyline', label: 'crazyline', gender: 'women' }
]