import React, { Component } from 'react';
import '../Magazine.css';


export class OutfitInMind extends Component {	
	
render() {
    return (
      <div className="content-pane">	 
          <h1 id="OutfitInMind">Importance of having an outfit in mind when buying clothes</h1>
            <p>Everyone likes some shopping from time to time, it is nice to give our wardrobe an upgrade once in a while. 
                It happens quite often, while we are out shopping, 
                that we spot a certain piece of clothing and our heart just gets stuck on it, 
                making you buy it no matter whether you need it or not. 
                However, it is important to keep certain pointers in mind when buying clothes, 
                because reckless shopping can result in an overflowing wardrobe and a weight on your bank account. 
            </p>
            <p>It is best to 
                <a href='/?p=mixmatch/shop/styleup_women'> create an outfit </a>
                in mind when buying a certain piece of clothing. </p>
            <p>Here are five reasons why this will benefit you:</p>
            <h2>#1 Helps You Decide If Its Really Worth It:</h2>
            <p>When you are skeptical about that certain clothing item that you have got your eye on, 
                try imaging how many outfits you can make with to decide if it is useful and actually worth buying.
            </p>
            <h2>#2 Saves Your Closet From Becoming A Clothing Store:</h2>
            <p>It’ll make things easier and keep you from buying clothes impulsively, 
                thus saving your closet from overflowing with clothes.
            </p>
            <h2>#3 Makes For A Smart Investment:</h2>
            <p>There might be a particular clothing item with a somewhat hefty price tag, 
                but if it helps you make multiple outfits for several occasions, then it just might be a smart investment.
            </p>
            <h2>#4 Assists In Making A Better Choice:</h2>
            <p>If you’re stuck between more than one article of clothing, 
                try thinking of which one will help you create more, and better outfits, and there you have your answer.
            </p>
            <h2>#5 Saves Time In Deciding An Outfit:</h2>
            <p>Since you have already thought of outfit ideas when you were buying that clothing item, 
                it will help you create outfits quickly when needed 
                without having to spend a lot of time trying to assemble one.
            </p>
            <p>The next time your heart is getting tugged by a certain item of clothing and you really wanna buy it, 
                make sure to have an outfit in your mind that you can use the article in. 
                Hopefully, this little guide has helped you in knowing the importance of
                 having an outfit in your mind when buying clothes 
                so that you won’t be troubled by urges 
                when <a href='/'>shopping for clothes.</a>
            </p>
                <b><a href='/'> Style Up! </a> </b>
            <br /><br /> <br />
	  </div>
    );
  }
}