import React, { Component } from 'react';
import queryString from 'query-string';
import './FiltersDialog.css';


export class FiltersDialog extends Component {
	constructor(props) {
        super(props); 

        this.setSearchParams = this.setSearchParams.bind(this);
        this.InitializeMaps = this.InitializeMaps.bind(this);
		this.loadSearchParams = this.loadSearchParams.bind(this);
        this.handleChangeShoes = this.handleChangeShoes.bind(this);
        this.handleChangeBottoms = this.handleChangeBottoms.bind(this);
        this.handleChangeTops = this.handleChangeTops.bind(this);
        this.handleChangeOcasion = this.handleChangeOcasion.bind(this);
        this.handleChangeSeason = this.handleChangeSeason.bind(this);
        this.cancel = this.cancel.bind(this);

        this.InitializeMaps();

		this.state = {				
			 //shirt: false,
			 //tshirt: false,
			 //blouse: false,					
			 //dress: false,
			 //jacket: false,			
			 //knitwear: false,
			 //sweatshirt: false,
			 //separator: false,							
			 //forDress: false,					
			 //jeans: false,
			 //trousers: false,
			 //shorts: false,
			 //skirt: false,
			 //separator: false,							
			 //flats: false,
			 //heeled: false,
			 //boots: false,
			 //sneakers: false,
			 //separator: false,						
			 //shirt: false,
			 //tshirt: false,
			 //jacket: false,					
			 //knitwear: false,
			 //sweatshirt: false,
			 //separator: false,							
			 //jeans: false,
			 //trousers: false,
			 //shorts: false,
			 //separator: false,							
			 //flats: false,
			 //boots: false,
             //sneakers: false
             wTops: this.wTopsMap,
             wBottoms: this.wBottomsMap,
             wShoes: this.wShoesMap,
             wSeasons: this.wSeasonsMap,
             wOccasions: this.wOccasionsMap,
             
             //display: tmpDisplay
		};
	}

	componentDidMount() {
        this.loadSearchParams();
        
        var that = this;
        window.onkeyup = function (event) {
            if (event.keyCode === 27) {
                that.cancel();
            }
        }
    }

    InitializeMaps() {
        this.wTopsMap = new Map();
        this.wTopsMap.set("shirt", false);
        this.wTopsMap.set("tshirt", false);
        this.wTopsMap.set("blouse", false);
        this.wTopsMap.set("dress", false);
        this.wTopsMap.set("jacket", false);
        this.wTopsMap.set("knitwear", false);
        this.wTopsMap.set("sweatshirt", false);

        this.wBottomsMap = new Map();
        this.wBottomsMap.set("forDress", false);
        this.wBottomsMap.set("jeans", false);
        this.wBottomsMap.set("trousers", false);
        this.wBottomsMap.set("shorts", false);
        this.wBottomsMap.set("skirt", false);

        this.wShoesMap = new Map();
        this.wShoesMap.set("flats", false);
        this.wShoesMap.set("heeled", false);
        this.wShoesMap.set("boots", false);
        this.wShoesMap.set("sneakers", false);

        this.wSeasonsMap = new Map();
        this.wSeasonsMap.set("summer", false);
        this.wSeasonsMap.set("fall", false);
        this.wSeasonsMap.set("winter", false);
        this.wSeasonsMap.set("spring", false);

        this.wOccasionsMap = new Map();
        this.wOccasionsMap.set("elegant", false);
        this.wOccasionsMap.set("sport", false);
        this.wOccasionsMap.set("casual", false);
        this.wOccasionsMap.set("beach", false);

    }

	loadSearchParams() {
		var values = queryString.parse(this.props.passedProps.location.search);
		if(values.topt) {
			var topsf = values.topt.split(",");
			if(topsf.includes("shirt")) this.setState(prevState => ({ wTops: prevState.wTops.set("shirt", true) }));
			if(topsf.includes("tshirt")) this.setState(prevState => ({ wTops: prevState.wTops.set("tshirt", true) }));
			if(topsf.includes("blouse")) this.setState(prevState => ({ wTops: prevState.wTops.set("blouse", true) }));
			if(topsf.includes("dress")) this.setState(prevState => ({ wTops: prevState.wTops.set("dress", true) }));
			if(topsf.includes("jacket")) this.setState(prevState => ({ wTops: prevState.wTops.set("jacket", true) }));
			if(topsf.includes("knitwear")) this.setState(prevState => ({ wTops: prevState.wTops.set("knitwear", true) }));
			if(topsf.includes("sweatshirt")) this.setState(prevState => ({ wTops: prevState.wTops.set("sweatshirt", true) }));
		}
		if(values.bottomt) {
			var bottomsf = values.bottomt.split(",");
			if(bottomsf.includes("forDress")) this.setState(prevState => ({ wBottoms: prevState.wBottoms.set("forDress", true) }));
			if(bottomsf.includes("jeans")) this.setState(prevState => ({ wBottoms: prevState.wBottoms.set("jeans", true) }));
			if(bottomsf.includes("trousers")) this.setState(prevState => ({ wBottoms: prevState.wBottoms.set("trousers", true) }));
			if(bottomsf.includes("shorts")) this.setState(prevState => ({ wBottoms: prevState.wBottoms.set("shorts", true) }));
            if(bottomsf.includes("skirt")) this.setState(prevState => ({ wBottoms: prevState.wBottoms.set("skirt", true) }));
        }
		/*if(values.shoest) {
			var shoesf = values.shoest.split(",");
			if(shoesf.includes("flats")) this.setState({flats:true});
			if(shoesf.includes("heeled")) this.setState({heeled:true});
			if(shoesf.includes("boots")) this.setState({boots:true});
			if(shoesf.includes("sneakers")) this.setState({sneakers:true});
        }*/
		if(values.shoest) {
			var shoesf = values.shoest.split(",");
			if(shoesf.includes("flats")) this.setState(prevState => ({ wShoes: prevState.wShoes.set("flats", true) }));
			if(shoesf.includes("heeled")) this.setState(prevState => ({ wShoes: prevState.wShoes.set("heeled", true) }));
			if(shoesf.includes("boots")) this.setState(prevState => ({ wShoes: prevState.wShoes.set("boots", true) }));
			if(shoesf.includes("sneakers")) this.setState(prevState => ({ wShoes: prevState.wShoes.set("sneakers", true) }));
        }
		if(values.season) {
			var seasonf = values.season.split(",");
			if(seasonf.includes("summer")) this.setState(prevState => ({ wSeasons: prevState.wSeasons.set("summer", true) }));
			if(seasonf.includes("fall")) this.setState(prevState => ({ wSeasons: prevState.wSeasons.set("fall", true) }));
			if(seasonf.includes("winter")) this.setState(prevState => ({ wSeasons: prevState.wSeasons.set("winter", true) }));
			if(seasonf.includes("spring")) this.setState(prevState => ({ wSeasons: prevState.wSeasons.set("spring", true) }));
        }
		if(values.occasion) {
			var occasionf = values.occasion.split(",");
			if(occasionf.includes("elegant")) this.setState(prevState => ({ wOccasions: prevState.wOccasions.set("elegant", true) }));
			if(occasionf.includes("sport")) this.setState(prevState => ({ wOccasions: prevState.wOccasions.set("sport", true) }));
			if(occasionf.includes("casual")) this.setState(prevState => ({ wOccasions: prevState.wOccasions.set("casual", true) }));
			if(occasionf.includes("beach")) this.setState(prevState => ({ wOccasions: prevState.wOccasions.set("beach", true) }));
        }
	}

	setSearchParams() {
		var topsf = "topt=";
		if (this.state.wTops.get("shirt")) topsf+="shirt,";
		if (this.state.wTops.get("tshirt")) topsf+="tshirt,";
		if (this.state.wTops.get("blouse")) topsf+="blouse,";
		if (this.state.wTops.get("dress")) topsf+="dress,";
		if (this.state.wTops.get("jacket")) topsf+="jacket,";
		if (this.state.wTops.get("knitwear")) topsf+="knitwear,";
		if (this.state.wTops.get("sweatshirt")) topsf+="sweatshirt,";

		if (topsf.includes(",")) {
			topsf = topsf.substring(0, topsf.length-1) + "&";
		} else {
			topsf = "";
		}

		var bottomsf = "bottomt=";
		if (this.state.wBottoms.get("forDress")) bottomsf+="forDress,";
		if (this.state.wBottoms.get("jeans")) bottomsf+="jeans,";
		if (this.state.wBottoms.get("trousers")) bottomsf+="trousers,";
		if (this.state.wBottoms.get("shorts")) bottomsf+="shorts,";
		if (this.state.wBottoms.get("skirt")) bottomsf+="skirt,";
		
		if (bottomsf.includes(",")) {
			bottomsf = bottomsf.substring(0, bottomsf.length-1) + "&";
		} else {
			bottomsf = "";
		}

		var shoesf = "shoest=";
		if (this.state.wShoes.get("flats")) shoesf+="flats,";
		if (this.state.wShoes.get("heeled")) shoesf+="heeled,";
		if (this.state.wShoes.get("boots")) shoesf+="boots,";
        if (this.state.wShoes.get("sneakers")) shoesf+="sneakers,";
		
		if (shoesf.includes(",")) {
			shoesf = shoesf.substring(0, shoesf.length-1) + "&";
		} else {
			shoesf = "";
		}

		var seasonf = "season=";
		if (this.state.wSeasons.get("summer")) seasonf+="summer,";
		if (this.state.wSeasons.get("fall")) seasonf+="fall,";
		if (this.state.wSeasons.get("winter")) seasonf+="winter,";
        if (this.state.wSeasons.get("spring")) seasonf+="spring,";
		
		if (seasonf.includes(",")) {
			seasonf = seasonf.substring(0, seasonf.length-1) + "&";
		} else {
			seasonf = "";
		}

		var occasionf = "occasion=";
		if (this.state.wOccasions.get("elegant")) occasionf+="elegant,";
		if (this.state.wOccasions.get("sport")) occasionf+="sport,";
		if (this.state.wOccasions.get("casual")) occasionf+="casual,";
        if (this.state.wOccasions.get("beach")) occasionf+="beach,";
		
		if (occasionf.includes(",")) {
			occasionf = occasionf.substring(0, occasionf.length-1) + "&";
		} else {
			occasionf = "";
		}

		var searchStr = topsf + bottomsf + shoesf + seasonf + occasionf;
		if (searchStr.includes("&")) {
            searchStr = searchStr.substring(0, searchStr.length-1);
		}
        this.props.passedProps.history.push({search:searchStr});

		window.location.reload();
    }
    
    cancel() {
        this.InitializeMaps();
        this.loadSearchParams();

        this.props.onClose();
    }

    handleChangeTops(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ wTops: prevState.wTops.set(item, isChecked) }));
    }

    handleChangeBottoms(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ wBottoms: prevState.wBottoms.set(item, isChecked) }));
    }

    handleChangeShoes(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ wShoes: prevState.wShoes.set(item, isChecked) }));
    }

    handleChangeSeason(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ wSeasons: prevState.wSeasons.set(item, isChecked) }));
    }

    handleChangeOcasion(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({ wOccasions: prevState.wOccasions.set(item, isChecked) }));
    }
    
  render() {	 
      var that = this; 
      const shoesItems = [];
      this.state.wShoes.forEach((value,key) => (
        shoesItems.push (
            <li className="filtersLi">
            <input type="checkbox" name={key} className="filter-checkbox" id={key}
                    checked={that.state.wShoes.get(key)} onChange={that.handleChangeShoes} />
            <label className="filter-label" for={key}>{`${key}`}</label>
            </li>
      )))
      const bottomItems = [];
      this.state.wBottoms.forEach((value,key) => (
        bottomItems.push (
            <li className="filtersLi">
            <input type="checkbox" name={key} className="filter-checkbox" id={key}
                     checked={that.state.wBottoms.get(key)} onChange={that.handleChangeBottoms} />
            <label className="filter-label" for={key}>{`${key}`}</label>
            </li>
      )))
      const topItems = [];
      this.state.wTops.forEach((value,key) => (
        topItems.push (
            <li className="filtersLi">
            <input type="checkbox" name={key} className="filter-checkbox" id={key}
                     checked={that.state.wTops.get(key)} onChange={that.handleChangeTops} />
            <label className="filter-label" for={key}>{`${key}`}</label>
            </li>
      )))
      const seasonItems = [];
      this.state.wSeasons.forEach((value,key) => (
        seasonItems.push (
            <li className="filtersLi">
            <input type="checkbox" name={key} className="filter-checkbox" id={key}
                     checked={that.state.wSeasons.get(key)} onChange={that.handleChangeSeason} />
            <label className="filter-label" for={key}>{`${key}`}</label>
            </li>
      )))
      const occasionItems = [];
      this.state.wOccasions.forEach((value,key) => (
        occasionItems.push (
            <li className="filtersLi">
            <input type="checkbox" name={key} className="filter-checkbox" id={key}
                     checked={that.state.wOccasions.get(key)} onChange={that.handleChangeOcasion} />
            <label className="filter-label" for={key}>{`${key}`}</label>
            </li>
      )))
    return ( 
        <div id="myModal" className="modal" style={{display:this.props.display}}>

        <div className="modal-content">
            <span className="close" onClick={() => this.cancel()}>&times;</span>
            <p className="filtersDialogHeader"><b>Filters</b></p>
            <div className="filtersInnerContent">
                <div className="filtersSection">
                    <div className="filtersSectionHeader"><b>Tops</b></div>
                    <ul>{topItems}</ul>
                </div>
                <div className="filtersSection">
                    <div className="filtersSectionHeader"><b>Bottoms</b></div>
                    <ul>{bottomItems}</ul>
                </div>
                <div className="filtersSection">
                    <div className="filtersSectionHeader"><b>Shoes</b></div>
                    <ul>{shoesItems}</ul>
                </div>
                <div className="filtersSection">
                    <div className="filtersSectionHeader"><b>Season</b></div>
                    <ul>{seasonItems}</ul>
                </div>
                <div className="filtersSection">
                    <div className="filtersSectionHeader"><b>Occasion</b></div>
                    <ul>{occasionItems}</ul>
                </div>
            </div>
            <button className="filterDialogButton" onClick={() => this.setSearchParams()}>Apply</button>
            <button className="filterDialogButton" onClick={() => this.cancel()}>Cancel</button>
        </div>
  
        </div>
); 
} 
}