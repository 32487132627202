import React, { Component } from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import {Helmet} from "react-helmet";
//import import_women from '../items/styleup_women.json';
//import import_women from '../items/styleup-women-tucked-in.json';
//import import_women from '../items/select.json';
import import_women from '../items/styleup-men.json';
import { createItem } from "../Utils/ItemsListUtils.js";

class TableHeader extends React.Component {
	render() {
			return (
				<div style={{position: "fixed", maxWidth:"100%", overflowX:"scroll", backgroundColor: "WHITE", paddingBottom: "20px", color: "BLUE"}}>
					<div style={{width: "100px", textAlign: "center", display: "inline-block"}}>Image</div>
					<div style={{width: "160px", textAlign: "center", display: "inline-block"}}>Dropbox link</div>
					<div style={{width: "160px", textAlign: "center", display: "inline-block"}}>Buy link</div>
					<div style={{width: "160px", textAlign: "center", display: "inline-block"}}>Description</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Brand</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Name(timestamp)</div>
					<div style={{width: "60px", textAlign: "center", display: "inline-block"}}>start</div>
					<div style={{width: "60px", textAlign: "center", display: "inline-block"}}>end</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Price</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Type</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Ocasion</div>
					<div style={{width: "120px", textAlign: "center", display: "inline-block"}}>Season</div>
					<div style={{width: "50", textAlign: "center", display: "inline-block"}}>Delete</div>
				</div>
			)
	}
}
	
class Item extends React.Component {
	constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick() {
		var res = window.confirm("Are you sure you want to delete this item?");
		if (res === true) {
			this.props.deleteCallback(this.props.sortIndex);
		}
	}

    render() {
        return (
					<div>
						<Helmet>
							<meta name="robots" content="noindex"></meta>
						</Helmet> 

						<img src={this.props.item.Path} style={{width: "100px", height: "100px", objectFit: "contain"}} key={this.key}/>
            <textarea type="text" defaultValue={this.props.item.Path} key={this.props.key}
									onChange={(event) => {this.props.item.Path = event.target.value;}} 
									style={{margin: "3px", padding: "2px", width: "150px", height: "70px"}}/>
						<textarea type="text" defaultValue={this.props.item.BuyLink} key={this.props.key}
									onChange={(event) => {this.props.item.BuyLink = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "150px", height: "70px"}} />
						<textarea type="text" defaultValue={this.props.item.Description} key={this.props.key}
									onChange={(event) => {this.props.item.Description = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "150px", height: "70px"}} />									
						<select type="text" defaultValue={this.props.item.Brand} key={this.props.key}
									onChange={(event) => {this.props.item.Brand = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} >									
										<option value="NA">NA</option>							
										<option value="ebay">Ebay</option>								
										<option value="amazon">Amazon</option>
						</select>
						<textarea type="text" defaultValue={this.props.item.timeStamp} key={this.props.key}
									onChange={(event) => {this.props.item.timeStamp = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} />
						<textarea type="text" defaultValue={this.props.item.top} key={this.props.key}
									onChange={(event) => {this.props.item.top = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "50px", height: "25px"}} />
						<textarea type="text" defaultValue={this.props.item.bottom} key={this.props.key}
									onChange={(event) => {this.props.item.bottom = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "50px", height: "25px"}} />
						<textarea type="text" defaultValue={this.props.item.Price} key={this.props.key}
									onChange={(event) => {this.props.item.Price = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} />
						<select type="text" defaultValue={this.props.item.Type} key={this.props.key}
									onChange={(event) => {this.props.item.Type = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} >									
									<option value="all">All</option>	
										<option value="separator" disabled>Womens tops</option>							
										<option value="shirt">Shirt</option>
										<option value="tshirt">Tshirt</option>
										<option value="blouse">Blouse</option>					
										<option value="dress">Dress</option>
										<option value="jacket">Jacket</option>			
										<option value="knitwear">Knitwear</option>
										<option value="sweatshirt">Sweatshirt</option>
										<option value="separator" disabled>Womens bottoms</option>							
										<option value="forDress">ForDress</option>					
										<option value="jeans">Jeans</option>
										<option value="trousers">Trousers</option>
										<option value="shorts">Shorts</option>
										<option value="skirt">Skirt</option>
										<option value="separator" disabled>Womens shoes</option>							
										<option value="flats">Flats</option>
										<option value="heeled">Heeled</option>
										<option value="boots">Boots</option>
										<option value="sneakers">Sneakers</option>
										<option value="separator" disabled>Mens tops</option>							
										<option value="shirt">Shirt</option>
										<option value="tshirt">Tshirt</option>
										<option value="jacket">Jacket</option>					
										<option value="knitwear">Knitwear</option>
										<option value="sweatshirt">Sweatshirt</option>
										<option value="separator" disabled>Mens bottoms</option>							
										<option value="jeans">Jeans</option>
										<option value="trousers">Trousers</option>
										<option value="shorts">Shorts</option>
										<option value="separator" disabled>Mens shoes</option>							
										<option value="flats">Flats</option>
										<option value="boots">Boots</option>
										<option value="sneakers">Sneakers</option>
						</select>
						<select type="text" defaultValue={this.props.item.Occasion} key={this.props.key}
									onChange={(event) => {this.props.item.Occasion = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} >									
									<option value="all">All</option>								
										<option value="elegant">Elegant</option>
										<option value="sport">Sport</option>
										<option value="casual">Casual</option>
										<option value="beach">Beach</option>
						</select>
						<select type="text" defaultValue={this.props.item.Season} key={this.props.key}
									onChange={(event) => {this.props.item.Season = event.target.value;}}
									style={{margin: "3px", padding: "2px", width: "110px", height: "25px"}} >									
									<option value="all">All</option>								
										<option value="summer">Summer</option>
										<option value="fall">Fall</option>
										<option value="winter">Winter</option>
										<option value="spring">Spring</option>
						</select>
						<button type="button" onClick={ () => this.handleClick() } 
									style={{margin: "3px", padding: "2px", width: "30px", height: "30px", display: "inline"}} >del</button>
					</div>
        )
    }
}
		
const SortableItem = SortableElement(({sortIndex, value, key, deleteCallback}) =>
	<div>
		<li>
			<Item item={value} sortIndex={sortIndex} key={key} imgkey={key} deleteCallback={deleteCallback}/>
		</li>
		<br />
	</div>
);

const SortableList = SortableContainer(({items, deleteCallback}) => {
	return (
		<ul>
			{items.map((value, index) => {
				var key = 'item-' + value;
				return (<SortableItem key={key} sortIndex={index} index={index} 
				value={value} key={Math.random()} deleteCallback={deleteCallback}/>);
			})}
		</ul>
	);
});
	
export class Admin extends Component {
	constructor(props) {
		super(props);
		this.initialized = false; 
		
		this.styleup_women = import_women;
		this.state = {
			tops: this.styleup_women.tops,
			bottoms: this.styleup_women.bottoms,
			shoes: this.styleup_women.shoes,
		};
		this.initialized = true;
		
		//this.loadCollection = this.loadCollection.bind(this);
		//this.createItem = this.createItem.bind(this);
		this.addTop = this.addTop.bind(this);
		this.addBottom = this.addBottom.bind(this);
		this.addShoes = this.addShoes.bind(this);
		this.deleteTop = this.deleteTop.bind(this);
		this.deleteBottom = this.deleteBottom.bind(this);
		this.deleteShoes = this.deleteShoes.bind(this);

		//this.loadCollection();
	}

	addTop() {
		this.styleup_women.tops.push(createItem());
    this.setState({
      tops: this.styleup_women.tops,
		});
		
		this.forceUpdate();
	}

	addBottom() {
		this.styleup_women.bottoms.push(createItem());
    this.setState({
      bottoms: this.styleup_women.bottoms,
		});
		
		this.forceUpdate();
		
	}

	addShoes() {
		this.styleup_women.shoes.push(createItem());
    this.setState({
      shoes: this.styleup_women.shoes,
		});
		
		this.forceUpdate();
		
	}

	deleteTop(index) {
		this.styleup_women.tops.splice(index,1);
    this.setState({
      tops: this.styleup_women.tops,
		});
		
		this.forceUpdate();
	}

	deleteBottom(index) {
		this.styleup_women.bottoms.splice(index,1);
    this.setState({
      bottoms: this.styleup_women.bottoms,
		});
		
		this.forceUpdate();
		
	}

	deleteShoes(index) {
		this.styleup_women.shoes.splice(index,1);
    this.setState({
      shoes: this.styleup_women.shoes,
		});
		
		this.forceUpdate();
		
	}

	loadCollection() {
		//this.styleup_women = await import(`../items/${this.props.match.params.collection + '.json'}`);
		this.styleup_women = import_women;
		this.setState({
			tops: this.styleup_women.tops,
			bottoms: this.styleup_women.bottoms,
			shoes: this.styleup_women.shoes,
		});
		this.initialized = true;
		this.forceUpdate();
	}
	
  onSortEndTops = ({oldIndex, newIndex}) => {
		this.styleup_women.tops = arrayMove(this.state.tops, oldIndex, newIndex);
    this.setState({
      tops: this.styleup_women.tops,
		});
		
		this.forceUpdate();
  };
	
  onSortEndBottoms = ({oldIndex, newIndex}) => {
		this.styleup_women.bottoms = arrayMove(this.state.bottoms, oldIndex, newIndex);
    this.setState({
      bottoms: this.styleup_women.bottoms,
    });
  };
	
  onSortEndShoes = ({oldIndex, newIndex}) => {
		this.styleup_women.shoes = arrayMove(this.state.shoes, oldIndex, newIndex);
    this.setState({
      shoes: this.styleup_women.shoes,
    });
  };
	
  render() {
	if (!this.initialized) return <div>Loading ...</div>;

    return (
			<div style={{whiteSpace: "nowrap"}}>			
				<div>
					<div style={{position: "fixed", backgroundColor: "WHITE", paddingRight: "500px", zIndex: "1000"}}>
					<button type="button" onClick={() => {
															var a = document.createElement('a');
															a.setAttribute('href', 'data:text/plain;charset=utf-u,'+encodeURIComponent(JSON.stringify(this.styleup_women, null, 4)));
															a.setAttribute('download', 'styleup_women.json');
															a.click()
														}}
									style={{margin: "50px", height: "40px", width: "150px", marginTop: "20px"}}>Save</button>
					<button onClick={()=>this.addTop()} type="button" style={{margin: "50px", height: "40px", width: "150px", marginTop: "20px"}}>Add Top</button>
					<button onClick={()=>this.addBottom()} type="button" style={{margin: "50px", height: "40px", width: "150px", marginTop: "20px"}}>Add Bottom</button>
					<button onClick={()=>this.addShoes()} type="button" style={{margin: "50px", height: "40px", width: "150px", marginTop: "20px"}}>Add Shoes</button>
					
					<TableHeader />
					</div>
				</div>
				<div style={{top: "170px", position: "absolute"}}>
					<b style={{marginLeft: "100px"}}>Tops</b>
					<SortableList items={this.state.tops} onSortEnd={this.onSortEndTops} deleteCallback={this.deleteTop}/>
					<b style={{marginLeft: "100px"}}>Bottoms</b>
					<SortableList items={this.state.bottoms} onSortEnd={this.onSortEndBottoms} deleteCallback={this.deleteBottom}/>
					<b style={{marginLeft: "100px"}}>Shoes</b>
					<SortableList items={this.state.shoes} onSortEnd={this.onSortEndShoes} deleteCallback={this.deleteShoes}/>
				</div>
			</div>
		);
  }
}