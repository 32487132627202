import React, { Component } from 'react';
import { StyleupHeader } from '../components/StyleupHeader';
import { Footer } from '../components/Footer';
import {Helmet} from "react-helmet";
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { NavBar } from '../components/NavBar';
import {AppBackground} from '../components/AppBackground';


export class About extends Component {
	constructor(props) {
		super(props); 

    SetPagePath('/?p=about');
	}
	
	
render() {
    return (
      <div className="App">	 
				<Helmet>
					<title>Mix and Match Outfit | Be The Stylist - StyleUp</title>
					{/*<meta name="robots" content="noindex"></meta>*/}
					{/*<link rel="canonical" href="https://styleupfashion.net/?p=about"></link>*/}
					<meta name="description" content="Become the stylist! Get inspired and explore the outfit combinations. Create your look with the mix and match interface." />
				</Helmet>   

				<AppBackground />

	  <div className="App-container" style={{display: "block"}}>
	  
		<StyleupHeader passedProps={this.props}/>
		
		<NavBar type="shop" collection="styleup_women"/>
		
		<div style={{marginLeft: '10px', marginBottom: "100px", marginTop: "120px", width: "100%", maxWidth:"1200px", display: "inline-block"}}>
			<b>
				<div style={{textAlign: 'left', display: "block"}}>Thank you for visiting :)</div>
				<div style={{marginTop: "40px", textAlign: 'left', display: "block"}}>We would be glad to hear from you:</div>
				<div style={{marginTop: "20px",textAlign: 'left', display: "block"}} >By email - <i>styleup@styleupfashion.net</i></div>
				<div style={{marginTop: "20px",textAlign: 'left', display: "block"}} >Or by   <a href="https://wa.me/972545314996" ><i>whatsapp</i></a></div>
				<div style={{marginTop: "40px", textAlign: 'left', display: "block"}}>And hey, we have an app, which you can also use for your own clothes.</div>
					<a style={{marginTop: "10px",float: 'left', textAlign: 'left'}} href="https://play.google.com/store/apps/details?id=com.styleup.styleup" >Download it here</a>
			</b>
		</div>
		
	  </div>	
		<Footer />
	  </div>
    );
  }
}