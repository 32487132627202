import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';
import {Helmet} from "react-helmet";
import { StyleupHeader } from '../components/StyleupHeader';
import {AppBackground} from '../components/AppBackground';


export class Nomatch extends Component {
	
render() {
    return (
      <div className="App">	 
				<Helmet>
					<title>Mix&Match Outfit | Be The Stylist - StyleUp</title>
					<meta name="robots" content="noindex"></meta>
					<meta name="description" content="Become the stylist! Get inspired and explore the outfit combinations. Create your look with the mix and match interface." />
				</Helmet>   				

				<AppBackground />

	  <div className="App-container">
	  
		<StyleupHeader />
		
		<div className="Body" style={{marginLeft: '10px', marginBottom: "100px"}}>
			<p />
			<p style={{float: 'left', textAlign: 'left'}}>Something went wrong.</p>
			<Link to="/" style={{float: 'left', textAlign: 'left'}}>Click here to return to the main page</Link>
			<p />
			<p />
		</div>
		
	  </div>	  
		<Footer />
	  </div>
    );
  }
  
}