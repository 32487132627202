

export function SetPagePath(pagePath) {    
    if (window.location.hostname !== "localhost") {
        SendAnalytics('config', 'UA-129788657-1', {'page_path': pagePath});
        SendAnalytics('config', 'UA-94126823-2', {'page_path': pagePath});
    }
}

export function HandleBuyClicks(link) {    
   if (window.location.hostname !== "localhost") { 
        SendAnalytics('event', 'click', {
            'event_category': 'buy link',
            'event_label': link
        });
    }
  }

export function AnalyticsTest() {    
    alert("analytics test"); 
    SendAnalytics('event', 'click', {
        'event_category': 'test cat',
        'event_label': 'test label'
    });
}

export function SendAnalytics() {  
    window.dataLayer = window.dataLayer || []; 
    window.dataLayer.push(arguments);
}