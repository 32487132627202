import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navBtn from '../pics/nav_btn.svg'; 
import './NavBar.css';
import ResponsiveMenu from 'react-responsive-navbar';
 
export class NavBar extends Component {

  constructor(props) {
    super(props);
    this.MMColor = "Black";
    this.ALLColor = "Black";
    this.BLOGColor = "Black";

    if (props.page === "mixandmatch") {
      this.MMColor = "#DD6600";
    } else if (props.page === "allitems") {
      this.ALLColor = "#DD6600";
    } else if (props.page === "blog") {
      this.BLOGColor = "#DD6600";
    }
    
		this.type = "shop";
		if 	(this.props.type !== undefined)	{
			this.type = this.props.type;
		}
    this.collection = "styleup_women";
		if 	(this.props.collection !== undefined)	{
			this.collection = this.props.collection;
		}

    if (!this.props.searchParams) {this.searchParams = "";}
    else {this.searchParams = this.props.searchParams;}
  }

  render() {
    return (
      <ResponsiveMenu
        menuOpenButton={<img src={navBtn} alt="open fashion menu" />}
        menuCloseButton={<div />}
        changeMenuOn="200px"
        largeMenuClassName="large-menu-classname"
        smallMenuClassName="small-menu-classname"
        menu={
            <ul>	
                {/*<li ><span className="Oleo-font" href="" style={{textDecoration: "none", color: "white"}}>{this.props.all-clothes}</span></li>*/}
                <li><b><Link className="Oleo-font" to={`/mixmatch/${this.type}/${this.collection}${this.searchParams}`} style={{color: this.MMColor}} rel="nofollow">Mix&Match</Link></b></li>
                <li><b><Link className="Oleo-font" to={`/all-clothes/${this.type}/${this.collection}${this.searchParams}`} style={{color: this.ALLColor}} rel="nofollow">All Clothes</Link></b></li>
                <li><b><Link className="Oleo-font" to={"/blog"} style={{color: this.BLOGColor}} rel="nofollow">Blog</Link></b></li>
            </ul>
        }
      />
    );
  }
}