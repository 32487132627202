import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';
import {Helmet} from "react-helmet";
import { StickyContainer, Sticky } from 'react-sticky';
import './Welcome.css';
import { AppBackground } from '../components/AppBackground';
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { OutfitInMind } from './Articles/OutfitInMind';
import { ImportanceOfGoodCombination } from './Articles/ImportanceOfGoodCombination';
import { ColorCombinationsPerSeason } from './Articles/ColorCombinationsPerSeason';
import { OutfitsWithYourItem } from './Articles/OutfitsWithYourItem';
import videoWomen from "../pics/w1.mp4";
import videoMen from "../pics/m1.mp4";
//import videomp from "../pics/bg_vid8.mp4";
//import videowebm from "../pics/video1_muted.webm";
//import chevronDown from "../pics/chevron-down-solid.svg";
//import bg_img from "../pics/person_bg/wood3.jpg";
import bg_img from "../pics/bgs/bricks1.jpg";


export class Welcome extends Component {
	constructor(props) {
		super(props); 

		SetPagePath('/');
		
		this.genderChangeToMen = this.genderChangeToMen.bind(this);		
		this.genderChangeToWomen = this.genderChangeToWomen.bind(this);	
		this.genderChangeToIrit = this.genderChangeToIrit.bind(this);	
	}
	
	componentDidMount() {		
		window.scrollTo(0, 0);
	}
	
	genderChangeToMen(e) {
		e.stopPropagation();
		this.props.genderChanged('men');
	}
	
	genderChangeToWomen(e) {
		e.stopPropagation();
		this.props.genderChanged('women', 'styleup_women.json');
	}
	
	genderChangeToIrit(e) {
		e.stopPropagation();
		this.props.genderChanged('women', 'irit.json');
	}
	
	
render() {
    return (
      <div className="Welcome" style={{textAlign: 'center',  overflow: "hidden"}}>				
				<Helmet>
					<title>Outfit inspiration and ideas, fashion shopping online | StyleUp Mix and Match</title>
					<link rel="canonical" href="https://styleupfashion.net"></link>
    				<meta name="keywords" content="outfit, total look, full look, full outfit, styleup, style, style up, fashion,  mix and match, color combination, colour combination, color combinations, colour combinations, outfit ideas, outfit combination, dress up" />
					<meta name="description" content="Find new outfit ideas and get inspired, experiment with outfit combinations online with our Mix and Match interface. 
														Start the online fashion search here, invent and complete your look. 
														Usually, each peace of clothing can be viewed only as part of a single look shown in the image.
														StyleUp shows the clothes in different outfits.
														With the Mix&Match interface you can see in what color combinations a garment can be used 
														and how it will fit in your wardrobe." />
				</Helmet>  

				<AppBackground />

				<div style={{ overflow: "hidden"}}>
					<div style={{width: "100vw", height: "100vh", 
								backgroundImage: `url(${bg_img})`, backgroundRepeat: "repeat",
								backgroundSize: "100% 100%"}}>
					</div>

					<div style={{	position: "absolute", top: "0", top: "0", right: "0", bottom: "0", left: "0",
												width: "100vw", height: "100vh", maxHeight: "1080px"}}> 
												{/*boxShadow: "0px 0px 5px 5px rgba(221,102,100,0.5)",
												backgroundColor: "rgba(255,255,255,0)",
												boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",*/}
						<Link to="/?p=mixmatch/shop/styleup_women" onClick={this.forceUpdate} style={{textDecoration: 'none'}}>
							<div style={{backgroundColor: "rgba(255,255,255,0)", 
													display: "block", padding: "2px 0px", 
													boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0)",
													borderRadius: "0px", marginTop: "0vh"}}>
								<div style={{display: "inline-block", minWidth: "350px", marginTop: "7vh",	
														backgroundColor: "rgba(255,255,255,0.8)",	
														boxShadow: "0px 0px 20px 3px rgba(0, 0, 0, 0.3)",
														width: "25vw", padding: "0px 0px",	borderRadius: "20px"}}>
									<h1 style={{marginTop: '0px', fontFamily: 'DancingScript-Bold', 
															fontSize: '6.0em', color: '#111'}}>			
															{/*backgroundColor: "rgba(255,255,255,0.5)", 		
															boxShadow: "0px 0px 20px 3px rgba(0, 0, 0, 0.3)",
															border: "1px solid rgba(100,100,100,1)",*/}
															{/*textShadow: '4px 4px 2px rgba(0,0,0,0.1)'*/}
										<b>StyleUp</b>
									</h1>
									<h2 style={{marginTop: '-60px', color: '#222', fontSize: '1.5em', 
															fontFamily: 'Raleway, Helvetica, Arial, sans-serif'}}>Interactive Fashion</h2>
															{/*fontFamily: 'Georgia, serif'}}
															textShadow: "-1px 0 rgba(245,245,245,0.5), 0 1px rgba(245,245,245,0.5), 1px 0 rgba(245,245,245,0.5), 0 -1px rgba(245,245,245,0.5)",*/}
								</div>
							</div>
						</Link>
						<div style={{position: "relative", top: "0", display: "inline-block", width: "100%", height: "50%", marginTop: "5vh"}}>
							<Link to="/?p=mixmatch/shop/styleup-men" onClick={this.forceUpdate} style={{textDecoration: 'none',
									display: "inline-block", height: "50vh", maxWidth: "40vw", marginTop: "calc(10vh + 0px)"}}>
								<video controls autoPlay loop muted preload="auto" id="video" 
										style={{position: "absolute", top: "0", right: "calc(45% - 10vh - 80px)", height:"100%", border: "solid black 1px", display: "inline-block"}}>
									<source src={videoMen} type="video/mp4" />
								</video>
								<div style={{position: "absolute", bottom: "15%", right: "calc(45% - 54px - 80px)", display: "inline-block"}}>
									<h3 
															style={{marginTop: '80px', color: 'white', fontSize: '1.1em',
															backgroundColor: "rgba(221,102,0,0.9)", padding: "10px 35px",
															borderRadius: "10px", borderColor: "white", borderWidth: "1px", borderStyle: "solid",
															boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.2)", 
															fontFamily: 'Raleway, Helvetica, Arial, sans-serif'}}>Men</h3>
								</div>
							</Link>
							<Link to="/?p=mixmatch/shop/styleup_women" onClick={this.forceUpdate} style={{textDecoration: 'none', 
									display: "inline-block", height: "50vh", maxWidth: "40vw", marginTop: "calc(10vh + 0px)"}}>
								<video controls autoPlay loop muted preload="auto" id="video" 
										style={{position: "absolute", top: "0", left: "calc(45% - 10vh - 80px)", height:"100%", border: "solid black 1px", display: "inline-block"}}>
									<source src={videoWomen} type="video/mp4" />
								</video>
								<div style={{position:"absolute", bottom: "15%", left:"calc(45% - 54px - 80px)", display: "inline-block"}}>
									<h3 
															style={{marginTop: '80px', color: 'white', fontSize: '1.1em',
															backgroundColor: "rgba(221,102,0,0.9)", padding: "10px 20px",
															borderRadius: "10px", borderColor: "white", borderWidth: "1px", borderStyle: "solid",
															boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.2)", 
															fontFamily: 'Raleway, Helvetica, Arial, sans-serif'}}>Women</h3>
								</div>
							</Link>
						</div>
							{/*<div style={{display:"inline-block", position: "absolute", top: "60vh", left: "calc(50vw - 120px)"}}>
								<h3 
														style={{marginTop: '80px', color: 'white', fontSize: '1.1em',
														backgroundColor: "rgba(221,102,0,0.85)", padding: "10px 50px",
														borderRadius: "10px", borderColor: "white", borderWidth: "1px", borderStyle: "solid",
														boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.2)", 
														fontFamily: 'Raleway, Helvetica, Arial, sans-serif'}}>Start Mix&Match</h3>
							</div>*/}
						{/*<div style={{position: "absolute", bottom: "2vh", left: "calc(50vw - 19px)"}}>
										<img className="downArrow" src={chevronDown} alt="chevronDown" 
										style={{padding: "1px", paddingBottom: "0px", width: "35px", height: "20px", 
														backgroundColor: "rgba(255,255,255,0.99)",
														boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
														objectFit: "fill", backgroundColor: "rgba(255,255,255,0.9)", borderRadius: "7px"}}/>
						</div>*/}
						{/*<Link to="/mixmatch/models/irit" onClick={this.genderChangeToIrit}
									style={{marginRight: '60px', fontSize: '1.3em'}}>
									MODELS
					</Link>
						<Link to="/mixmatch/shop/styleup_women" onClick={this.genderChangeToWomen}
									style={{marginLeft: '60px', fontSize: '1.3em'}}>
									SHOP
						</Link>
						<Link to="/mixmatch/shop/styleup_women" onClick={this.genderChangeToWomen}
									style={{fontSize: '1.3em', backgroundColor: "white", color: "#dd6600", padding: "20px", borderRadius: "10px"}}>
									Mix&Match
						</Link>
						<Link to="/mixmatch" onClick={this.genderChangeToMen}
									style={{paddingRight: '10px', fontSize: '1.3em'}}>
									MEN
							</Link>
							<span >|</span>
							<Link to="/mixmatch" onClick={this.genderChangeToWomen}
									style={{paddingLeft: '10px', fontSize: '1.3em'}}>
									WOMEN
						</Link>*/}
							{/*<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}

						{/*<p style={{fontSize: '1.3em'}}><i>Download our app to mix&match your personal wardrobe </i>
							<a href="https://play.google.com/store/apps/details?id=com.styleup.styleup" >here</a>
						</p>*/}
					</div>
				</div>	 

				<div className="cards-contaner" style={{display: "inline-block", margin: "20px 0px", 
														width: "100%", maxWidth:"1200px"}}>
				
						<div className="landingpage-card" >
								<h3 className="card-header">What is the Mix&Match interface?</h3>
													<div className="card-content">With the Mix&Match interface you create outfits by combining three parts - top, bottom, and shoes.
																												<br />
																												{/*You can use filters to select a specific type of items and switch between tucked and untucked views.*/}
																												{/*Currently there are only clothing from Amazon and Ebay, but more will be available soon. */}
																												Our goal is to allow you to explore the posiblities online,
																												and to help to discover your fashion taste. Enjoy.
																												 </div>
						</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Start your fashion shopping here</h3>
													<div className="card-content">Usually, each peace of clothing can be viewed only as part of a single look shown in the image.
																												StyleUp shows the clothes in different outfits.
																												With the Mix&Match interface you can see in what color combinations a garment can be used 
																												and how it will fit in your wardrobe. </div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Inspiration and outfit ideas</h3>
													<div className="card-content">Everyone wants to make immpression, to be original in how they look.
																												<br /> Let us help.
																												Browse our website, create outfits by yourself, 
																												try different color combinations and
																												get crazy with your looks.
																												<br />Be creative. Get inspired! </div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Stop guessing</h3>
													<div className="card-content">When considering to purchase a piece of clothing, we try to imagine outfits in our head.
																												StyleUp is here to help you with this task, 
																												you create various looks with the Mix&Match interface and see the full outfits instead of imagining them.
																												You get to experiment with outfits before the purchase.</div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Complete your outfit</h3>
													<div className="card-content">Do you have an amazing top but no bottom to create the total look that you imagine?
																												Use our website to find the perfect peace to complete your look.
																												<br />
																												Looking for outfit ideas with a black skirt? 
																												Pick a skirt from our items and mix it with different tops and shoes to find the desired look.</div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Fashion shopping online</h3>
													<div className="card-content">Ebay and Amazon online fashion shopping starts from 
																												searching for the item which meets your taste and style. 
																												But the pictures only show the outfits from the sellers' point of view,
																												you have something else entirely in your mind...
																												That's what we are here for :) </div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">It is Free</h3>
													<div className="card-content">It is totaly free to use our Mix&Match interface.<br/>
																												We participate in the affilite programs of Amazon and Ebay,
																												so when you buy from them they pay us, with no additional costs for you. </div>
											</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Do you own an online shop or a fashion brand?</h3>
													<div className="card-content">Do you like our Mix&Match inteface and would like to have it on your website?
																												Easy - send us images of your clothes, then embed the webpage we give you. That's it.
																												<br /><Link to="/?p=about" rel="nofollow" onClick={this.forceUpdate}>Contact us for pricing details</Link>
													</div>
						</div>
													
						<div className="landingpage-card" >
													<h3 className="card-header">Do you own a brik-and-mortor shop?</h3>
													<div className="card-content">Place digital screens with Mix&Match catalog to attract customers and increase your conversion rates. 
																												<br /><Link to="/?p=about" rel="nofollow" onClick={this.forceUpdate}>Contact us for more details</Link>
													</div>
						</div>
				
				</div>

				<div className="articles" style={{display: "inline-block", marginBottom: "100px", 
														width: "100%", maxWidth:"1200px"}}>
					<h1 style={{color:"#444"}}>Articles</h1>
					<StickyContainer style={{display: "inline-block", width: "100%", maxWidth:"1200px"}}>
						<Sticky> 
							{({ style }) => (
								<div style={style}>
									<ul style={{marginLeft:"1%", maxWidth:"18%", textAlign:"left", fontFamily: "'Muli', sans-serif",
												position:"fixed", top:"60px", zIndex:"-2"}}>
										<li style={{marginBottom : "30px"}}><b><Link to="/#ImportanceOfGoodCombination" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Right outfit combination</Link></b></li>
										<li style={{marginBottom : "30px"}}><b><Link to="/#OutfitInMind" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Have an outfit in mind when shopping</Link></b></li>
										<li style={{marginBottom : "30px"}}><b><Link to="/#ColorCombinationsPerSeason" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Color combinations for every season</Link></b></li>
										<li style={{marginBottom : "30px"}}><b><Link to="/#OutfitsWithYourItem" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Outfits with the garment you want</Link></b></li>
									</ul>
								</div>
							)}
						</Sticky>
						<div style={{marginLeft: "22%", width:"95%"}}>
							<ImportanceOfGoodCombination/>
							<OutfitInMind/>
							<ColorCombinationsPerSeason/>
							<OutfitsWithYourItem/>
						</div>
					</StickyContainer>
					</div>

				<div className="social"></div>

				<Footer />
	  	</div>
    );
  }
}