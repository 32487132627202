
export function GetItemIndex(collection, timestamp) {
    var res = 0;
    collection.map(function(listValue, index){
        if (String(listValue.timeStamp) === String(timestamp)) {
            res = index;
            return;
        }
    });
    return res;
}


export function ApplyFilters(collection, filters) {
    var res = collection.slice();
    var shouldRemove = [];
    collection.map(function(listValue, index){
        if (filters.brands.length > 0) {
            if (!filters.brands.includes(listValue.Brand)) {
                if (shouldRemove.indexOf(listValue.timeStamp) === -1) {
                    shouldRemove.push(listValue.timeStamp);
                }
            }
        }
        if (filters.occasion.length > 0) {
            if (!filters.occasion.includes(listValue.Occasion)) {
                if (shouldRemove.indexOf(listValue.timeStamp) === -1) {
                    shouldRemove.push(listValue.timeStamp);
                }
            }
        }
        if (filters.season.length > 0) {
            if (!filters.season.includes(listValue.Season)) {
                if (shouldRemove.indexOf(listValue.timeStamp) === -1) {
                    shouldRemove.push(listValue.timeStamp);
                }
            }
        }
        if (filters.types.length > 0) {
            if (!filters.types.includes(listValue.Type)) {
                if (shouldRemove.indexOf(listValue.timeStamp) === -1) {
                    shouldRemove.push(listValue.timeStamp);
                }
            }
        }
    });
    shouldRemove.forEach( (element) => {
        res.splice(GetItemIndex(res,element),1);
    });

    var newItem = createItem();
    newItem.timeStamp = "000";
    newItem.bottom = "1000";
    if (res.length <= 0) res.push(newItem);

    return res;
}

export function createItem() {
    var currentDate = new Date();
    var date = currentDate.getDate();
    var month = currentDate.getMonth(); //January is 0 not 1
    var year = currentDate.getFullYear();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();

    var dateString = year + "" + (month + 1) + "" + date + "" + hours + "" + minutes + "" + seconds;

    return {
     "BuyLink":"",
     "Description":"",
     "ID": parseInt(dateString),
     "Brand":"",
     "NumberOfUses":0,
     "Occasion":0,
     "Order":"0",
     "Path":"",
     "Price":0,
     "Season":0,
     "Type":0,
     "bottom":0,
     "brightness":0,
     "isShared":"false",
     "magnify":0,
     "marginSide":0,
     "marginSide2":0,
     "marginTopAddition":0,
     "packingList":"Default",
     "profile":"default",
     "relatedTo":"",
     "timeStamp":dateString,
     "top":0
    }
}