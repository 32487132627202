
import React, { Component } from 'react';
import './AppBackground.css';
//import bgimg from "../pics/bgs/bg22.png";
//import bgimg from "../pics/bgs/bg101.jpg";
//import bgimg from "../pics/bgs/bg66.png";
//import bgimg from "../pics/bgs/transparentbwbg.png";
//import bgimg from "../pics/bgs/transparentcolorbg.png";
import logo from '../pics/logo.png';
import bg_img from "../pics/bgs/jeans.jpg";
 
export class AppBackground extends Component {

    render() {
        return ( 
            <div className="app-background" style={{opacity: "0.1", width: "100vw", height: "100vh", backgroundImage: `url(${bg_img})`, 
                                                    backgroundRepeat: "repeat", backgroundSize: "auto 100%", marginLeft: "0px"}}>
                        {/*<img style={{objectFit: "contain", width: "100vw", height: "100vh"}} src={logo} alt="smoke background"/>*/}
            </div>
        );
    }
}
