import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Magazine.css';

import one from './images/StyleUpItemsReview/1.png';
import two from './images/StyleUpItemsReview/2.png';
import three from './images/StyleUpItemsReview/3.png';
import four from './images/StyleUpItemsReview/4.png';
import five from './images/StyleUpItemsReview/5.png';
import six from './images/StyleUpItemsReview/6.png';
import seven from './images/StyleUpItemsReview/7.png';
import eight from './images/StyleUpItemsReview/8.png';
import nine from './images/StyleUpItemsReview/9.png';
import ten from './images/StyleUpItemsReview/10.png';
import eleven from './images/StyleUpItemsReview/11.png';
import twelve from './images/StyleUpItemsReview/12.png';
import thirteen from './images/StyleUpItemsReview/13.png';
import fourteen from './images/StyleUpItemsReview/14.png';
import fifteen from './images/StyleUpItemsReview/15.png';


export class OutfitsWithYourItem extends Component {	
	
render() {
    return (
    <div className="content-pane">	 
        <h1 id="OutfitsWithYourItem">Create outfits with the garment you want to buy</h1>
          <p>Every day morning simply isn’t made for trying out outfits for hours. 
              Subsequent to spending the entire weekend outing or watching Netflix, 
              it's elusive to inspire yourself and put on anything fascinating than sweatpants and tee. 
              On the real side of the story, the duty calls. As though getting up at the break of day isn't sufficiently unpleasant, 
              you do need to get robbed and appear like the efficient yet modern individuals from society. 
              It generally winds up with most of the women wildly hauling stuff out of their closets 
              since we have an inclination that we have nothing to wear. That’s where our platform steps in for your rescue. 
              We allow you to pair each clothing item with its complements right before you proceed to purchase it. 
              Everyday outfit combo dilemmas solved! 
          </p>
          <p>Don’t believe it? Well, our top-selling item is the ultra-modern knit top; 
              well-organized, textured, trendy and available in 3 divine pastel colors i.e. grey, beige and rose pink. 
              To give you a little more heads up here’s are the top 5 mix-n-match with the knit top just for you: 
            </p> 
            <div className="imgs">    
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201135640~and~bottomn=20181103220912~and~shoesn=20181208180359`} onClick={this.forceUpdate}>    
                    <img style={{paddingRight: "20px"}} src={one} alt="Mini Denim Shorts" />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201142151~and~bottomn=20181103220912~and~shoesn=20181208180359`} onClick={this.forceUpdate}>
                    <img style={{paddingRight: "20px"}} src={two} alt="Mini Denim Shorts" />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201143429~and~bottomn=20181103220912~and~shoesn=20181208180527`} onClick={this.forceUpdate}>
                    <img src={three} alt="Mini Denim Shorts" />
                </Link>
          </div>   
          <div className="imgs"><i style={{fontSize:"0.8em"}}>Click the image to start from the outfit</i></div>
          <h2>1. Mini Denim Shorts</h2>
          <p>This ensemble speaks volumes for an effortless and versatile look. As a chic courageous girl, what would you want more than a pastel knit top paired with mini denim short and sneakers? It is perfect for anyone who is looking for wearables that’d have them flaunt off their sleek legs. 
            </p>    
            <div className="imgs">  
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201135640~and~bottomn=20180212161801~and~shoesn=20190212131501`} onClick={this.forceUpdate}>
                    <img style={{paddingRight: "20px"}} src={four} alt="Wide Leg Linen pants " />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201142151~and~bottomn=20180212161801~and~shoesn=20190212131501`} onClick={this.forceUpdate}>
                    <img style={{paddingRight: "20px"}} src={five} alt="Wide Leg Linen pants " />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201143429~and~bottomn=20180212161801~and~shoesn=20190212131501`} onClick={this.forceUpdate}>
                    <img src={six} alt="Wide Leg Linen pants " />
                </Link>
          </div>
          <div className="imgs"><i style={{fontSize:"0.8em"}}>Click the image to start from the outfit</i></div>
          <h2>2. Wide Leg Linen pants </h2>
          <p>If there is anything comfortable other than a pajama and is also decent to wear outside is this linen pant. Loose fitting, wide leg and 100% linen, it makes it light and breezy to wear with a knit top. It’s paired with a closed shoe because flats ensure you are able to walk around shopping, lunching or moving seamlessly. 
            </p>
            <div className="imgs">
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201135640~and~bottomn=20181103235252~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                    <img style={{paddingRight: "20px"}} src={seven} alt="White skirt with sleek black stripes " />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201142151~and~bottomn=20181103235252~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                    <img style={{paddingRight: "20px"}} src={eight} alt="White skirt with sleek black stripes " />
                </Link>
                <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201143429~and~bottomn=20181103235252~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                    <img src={nine} alt="White skirt with sleek black stripes " />
                </Link>
          </div>
          <div className="imgs"><i style={{fontSize:"0.8em"}}>Click the image to start from the outfit</i></div>
          <h2>3. White skirt with sleek black stripes </h2>
          <p>Sometimes all you need is a dose of confidence. You get to spice up your evening attire with this fabulous combination of white skirt and knit top. A fancy pair of sandals help you complete your jazzy look. It’d definitely make a ravishing look for a lunch date too. 
          </p>
          <div className="imgs">
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201143429~and~bottomn=20181105003331~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                <img style={{paddingRight: "20px"}} src={ten} alt="Black skirt with thin white stripes " />
            </Link>
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201142151~and~bottomn=20181105003331~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                <img style={{paddingRight: "20px"}} src={eleven} alt="Black skirt with thin white stripes " />
            </Link>
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201135640~and~bottomn=20181105003331~and~shoesn=20181108171614`} onClick={this.forceUpdate}>
                <img src={twelve} alt="Black skirt with thin white stripes " />
            </Link>
          </div>
          <div className="imgs"><i style={{fontSize:"0.8em"}}>Click the image to start from the outfit</i></div>
          <h2>4. Black skirt with thin white stripes </h2>
          <p>White can never win over the exquisiteness of black. Black goes with every color, and that’s the reason no one can deny its allure. When doubt hits you, you opt for a black skirt and a casual knit top and head on to a night out with friends adding a little charm to your look with a pair of black heels. 
          </p>
          <div className="imgs">
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201143429~and~bottomn=20181205225738~and~shoesn=20181202182321`} onClick={this.forceUpdate}>
                <img style={{paddingRight: "20px"}} src={thirteen} alt="Casual black skinny pants " />
            </Link>
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201142151~and~bottomn=20181205225738~and~shoesn=20181202182321`} onClick={this.forceUpdate}>
                <img style={{paddingRight: "20px"}} src={fourteen} alt="Casual black skinny pants " />
            </Link>
            <Link to={`/?p=mixmatch/shop/styleup_women&q=topn=20181201135640~and~bottomn=20181205225738~and~shoesn=20181202182321`} onClick={this.forceUpdate}>
                <img src={fifteen} alt="Casual black skinny pants " />
            </Link>
          </div>
          <div className="imgs"><i style={{fontSize:"0.8em"}}>Click the image to start from the outfit</i></div>
          <h2>5. Casual black skinny pants </h2>
          <p>The best part of this guise is that you get to keep it simple and cool. Super easy, flattering and absolutely pleasing. Rather than choosing a navy blue or a pale blue jean, you go for a striking black skinny pant to accentuate a pastel knit top and metallic sneakers. Casual day at college/work never had been that gracefully chic! 
          </p>
          <p>We not only provide you with stunning clothing articles to buy but also a chance for you to visualize your complete look, because why not! 
              You get to 
                
              <b><a href='/?p=mixmatch/shop/styleup_women'> mix and match </a></b> 
              knitwear or a kicky top with a variety of bottoms and multiple edgy sandals. 
              You can imagine yourself in the amazing look first rather than regretting your decision later 
              and spending hours deciding what to wear with what exactly. 
              Are you ready to mix and match some basic staples for everyday wear?     
              <b><a href='/'> Style up!</a></b>             
          </p>
          <br /><br /> <br />
    </div>
    );
  }
}