
export function CalcSizeProportion() { 
    var height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
    //alert("Height:" + height);
    var sizeProportion;
    if (height >= 920) {
      sizeProportion = 0.8;
    } else if (height >= 870) {
      sizeProportion = 0.75;	
    } else if (height >= 820) {
      sizeProportion = 0.7;	
    } else if (height >= 770) {
      sizeProportion = 0.65;	
    } else if (height >= 720) {
      sizeProportion = 0.6;
    } else if (height >= 670) {
      sizeProportion = 0.55;	
    } else if (height >= 620) {
      sizeProportion = 0.5;	
    } else if (height >= 570) {
      sizeProportion = 0.45;	
    } else if (height >= 520) {
      sizeProportion = 0.4;	
    } else{
      sizeProportion = 0.35;	
    } 
    
    return sizeProportion;
}