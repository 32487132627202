import React, { Component } from 'react';
import '../Magazine.css';
import AbsolutelyGorgeousCoralAndNavyBlue from './images/AbsolutelyGorgeousCoralAndNavyBlue.png';
import ALovelyMixOfPurpleAndSilver from './images/ALovelyMixOfPurpleAndSilver.png';
import ANiceComplimentWithGrayAndYellow from './images/ANiceComplimentWithGrayAndYellow.png';
import BlueAndMaroon from './images/BlueAndMaroon.png';
import BrightAndLivelyBlueAndYellow from './images/BrightAndLivelyBlueAndYellow.png';
import DashingGoldAndBlack from './images/DashingGoldAndBlack.png';
import ElegantMixOfCopperAndWhite from './images/ElegantMixOfCopperAndWhite.png';
import RichDarkGreenAndBrown from './images/RichDarkGreenAndBrown.png';
import TheClassicBlackandWhite from './images/TheClassicBlackandWhite.png';
import TheTrustedNeutrals from './images/TheTrustedNeutrals.png';


export class ColorCombinationsPerSeason extends Component {	
	
render() {
    return (
      <div className="content-pane">	 
          <h1 id="ColorCombinationsPerSeason">Best Color Combinations in Outfits for Every Season</h1>
            <p>The key to constructing the perfect outfit is to find the right color combinations to match and pair up. 
                Pairing up different shades of the same color, 
                or the contrasting colors will allow you to brighten up and make your outfit look eye-catching. 
                Knowing the right 
                <a href='/?p=mixmatch/shop/styleup_women'> color combinations </a>
                suiting the event or the season is being one step closer to making the best outfit. 
                Don’t stick to the plain one toned colored outfits just because you’re afraid of not getting the combinations right, 
                experiments with the colors and get the right ones paired to get the best outfit. 
                If you want to experiment with different combinations before the purchase,  
                <a href='/'> You are in right place.</a>
            </p>
            <p>Let’s check out some of the coolest and trusty color combinations in outfits!</p>    
            <div className="imgs">           
            <img src={TheClassicBlackandWhite} alt="The Classic Black and White" />
            </div>
            <h2>#1 The Classic Black and White:</h2>
            <p>No matter what the season or what the time of the day is, you just can't go wrong with black and white. The classic combination is ideal for creating classy and beautiful outfits whether it is for a formal occasion or a casual.
            </p>       
            <div className="imgs">   
            <img src={TheTrustedNeutrals} alt="The Trusted Neutrals" />
            </div>
            <h2>#2 The Trusted Neutrals:</h2>
            <p>When it comes to combining colors to make an outfit, you can always trust the neutrals. The right mix of shades of neutral colors would help create the perfect outfit while keeping the soft, elegant touch.
            </p>
            <div className="imgs">   
            <img src={BrightAndLivelyBlueAndYellow} alt="Bright and Lively Blue and Yellow" />
            </div>
            <h2>#3 Bright and Lively Blue and Yellow:</h2>
            <p>This one is specifically for summer or spring. Create a lively outfit with some blue jeans and a bright yellow top to go with the season’s vibes.
            </p>
            <div className="imgs">   
            <img src={DashingGoldAndBlack} alt="Dashing Gold and Black" />
            </div>
            <h2>#4 Dashing Gold and Black:</h2>
            <p>Create a dashing outfit perfect for a night out with your girls with the absolutely glamorous combination of black and gold. This combination will especially be amazing for hitting the club!
            </p>
            <div className="imgs">   
            <img src={RichDarkGreenAndBrown} alt="Rich Dark Green and Brown" />
            </div>
            <h2>#5 Rich Dark Green and Brown:</h2>
            <p>This is one combination which all the seasons would agree upon. The deep and rich combination of dark green and brown will bring out every type of skin tone and compliment it beautifully. 
            </p>
            <div className="imgs">   
            <img src={ANiceComplimentWithGrayAndYellow} alt="A Nice Compliment With Gray and Yellow" />
            </div>
            <h2>#6 A Nice Compliment With Gray and Yellow:</h2>
            <p>The colors gray and yellow might be from different areas of the color wheel but that is what makes both the shades complement each other so well. Plus, this combination works just fine for daytime as well as nighttime.
            </p>
            <div className="imgs">   
            <img src={BlueAndMaroon} alt="Blue and Maroon" />
            </div>
            <h2>#7 Blue and Maroon:</h2>
            <p>This combination without a doubt is one of the fanciest and absolutely gorgeous looking due to its fancy effect, credit to the depth and intensity of both blue and maroon colors, best suited for nighttime events.
            </p>
            <div className="imgs">   
            <img src={ALovelyMixOfPurpleAndSilver} alt="A Lovely Mix of Purple and Silver" />
            </div>
            <h2>#8 A Lovely Mix of Purple and Silver:</h2>
            <p>If you are looking for the perfect outfit combination and style in soft yet strong colors especially for a formal event like prom, then a lovely mix of purple and silver is just what you need.
            </p>
            <div className="imgs">   
            <img src={ElegantMixOfCopperAndWhite} alt="Elegant Mix of Copper and White" />
            </div>
            <h2>#9 Elegant Mix of Copper and White:</h2>
            <p>A mix of copper and white colors would make a strong, statement-making combination without overdoing the mix.
            </p>
            <div className="imgs">   
            <img src={AbsolutelyGorgeousCoralAndNavyBlue} alt="Absolutely Gorgeous Coral and Navy Blue" />
            </div>
            <h2>#10 Absolutely Gorgeous Coral and Navy Blue:</h2>
            <p>Take the color of the year, living coral, and pair it with navy blue with a modern, lively, and irresistibly beautiful color combination.
            </p>
            <p>Now that you know which colors would make for some amazing combinations, 
                don’t worry about having to stick to the plain old single color palette. 
                Try and experiment with multiple colors together to construct the best, mind-blowing outfit. 
                Don’t hesitate to try your own ideas and pair up different colors. 
                <a href='/?p=mixmatch/shop/styleup_women'> Try these combination tricks </a> 
                and make your outfits look classier and fashionable. 
                We hope these would help you in looking your best at every event.                  
            </p>
                <b><a href='/'> Style Up! </a> </b>
            <br /><br /> <br />
	  </div>
    );
  }
}