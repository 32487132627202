import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


export class Footer extends Component {

  render() {	  
    return ( 
			<div>
				
				<div className="bottomNavigation" style={{bottom: "17px"}}>
					<Link className="bottomNavigationLink" to={"/?p=mixmatch/shop/styleup_women"} onClick={this.forceUpdate}>Mix&Match</Link>
					<span style={{padding: '0 5px'}}>|</span>
          <Link className="bottomNavigationLink" to={"/?p=all-clothes/shop/styleup_women"} onClick={this.forceUpdate}>All Clothes</Link>
					<span style={{padding: '0 5px'}}>|</span>
          <Link className="bottomNavigationLink" to={"/?p=blog"} onClick={this.forceUpdate}>Blog</Link>
					<span style={{padding: '0 5px'}}>|</span>
					<a className="bottomNavigationLink" href="https://www.facebook.com/styleupfashion.net/"><i className="fa fa-facebook" style={{width: "20px"}}></i></a>
					<span style={{padding: '0 5px'}}>|</span>
					<a className="bottomNavigationLink" href="http://instagram.com/styleup.mixandmatch/"><i className="fa fa-instagram" style={{width: "20px"}}></i></a>
				</div>

				<footer style={{position: "absolute", bottom: "0px", width: "100%", fontSize:"0.8em"}}>
					{/*styleup2018@gmail.com*/}
					<Link className="about-link footerNavigationLink" to="/?p=About" rel="nofollow" onClick={this.forceUpdate}>About us</Link>
					<span style={{padding: '0 5px'}}>|</span>
					<a className="footerNavigationLink" href="https://play.google.com/store/apps/details?id=com.styleup.styleup" >Google play</a>
					<span style={{padding: '0 5px'}}>|</span>
					<Link className="footerNavigationLink" to="/?p=PrivacyPolicy" rel="nofollow" onClick={this.forceUpdate}>Privacy Policy</Link>
				</footer>	
			</div>
	); 
  }  
}