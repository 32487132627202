import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './Magazine.css';
import { StyleupHeader } from '../components/StyleupHeader';
import { Footer } from '../components/Footer';
import { OutfitInMind } from './Articles/OutfitInMind';
import { ImportanceOfGoodCombination } from './Articles/ImportanceOfGoodCombination';
import { ColorCombinationsPerSeason } from './Articles/ColorCombinationsPerSeason';
import { OutfitsWithYourItem } from './Articles/OutfitsWithYourItem';
import { SetPagePath } from '../Utils/AnalyticsUtils';
import { AppBackground } from '../components/AppBackground';

var Tag;

export class Magazine extends Component {
	constructor(props) {
		super(props); 	
		
		SetPagePath(`/?p=blog/${this.props.match.params.topic}`);

		this.state = {topic: "Importance of outfit combination"};
		
		if (this.props.match.params.topic === "Importance of outfit combination") {
			Tag = ImportanceOfGoodCombination;
		}
		else if (this.props.match.params.topic === "Have an outfit in mind when shopping") {
			Tag = OutfitInMind;
		}
		else if (this.props.match.params.topic === "Color Combinations for Every Season") {
			Tag = ColorCombinationsPerSeason;
		}
		else if (this.props.match.params.topic === "Outfits with the garment you want") {
			Tag = OutfitsWithYourItem;
		}
		else {
			Tag = OutfitsWithYourItem;
			this.props.match.params.topic = "Outfits with the garment you want";
		}
	}

	componentDidMount() {
    	window.scrollTo(0, 0);
  }	
	
render() {
    return (
      <div className="App">	 
				<Helmet>
					<title>{`Mix and Match Outfit | ${this.props.match.params.topic}`}</title>
					<meta name="robots" content="noindex"></meta>
					{/*<link rel="canonical" href={`https://styleupfashion.net/?p=blog/${this.props.match.params.topic}`}></link>*/}
					<meta name="description" content="Become the stylist! Get inspired and explore the clothes combinations. Get outfit ideas. Create your look with the mix and match interface." />
				</Helmet> 				

				<AppBackground />

	  <div className="App-container">
	  
		<StyleupHeader passedProps={this.props} page="blog"/>
		
		<div className="Body-magazine">
            <div className="topics-pane">
                <ul >
                    <li style={{marginBottom : "30px"}}><b><Link to="/?p=blog/Importance of outfit combination" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Right outfit combination</Link></b></li>
                    <li style={{marginBottom : "30px"}}><b><Link to="/?p=blog/Have an outfit in mind when shopping" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Have an outfit in mind when shopping</Link></b></li>
                    <li style={{marginBottom : "30px"}}><b><Link to="/?p=blog/Color Combinations for Every Season" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Color combinations for every season</Link></b></li>
                    <li style={{marginBottom : "30px"}}><b><Link to="/?p=blog/Outfits with the garment you want" style={{lineHeight : "1.6em", color: "#dd6600", textDecoration: "none"}} onClick={this.forceUpdate}>Outfits with the garment you want</Link></b></li>
                </ul>
            </div>
			<Tag />
		</div>
		
	  </div>	
		<Footer />
	  </div>
    );
  }
}