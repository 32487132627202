
import { Component } from 'react';
import queryString from 'query-string';


export class GithubRedirect extends Component {

    constructor(props) {
        super(props); 

        var ua = window.navigator.userAgent;
        var edge = ua.indexOf('Edge/');
        if (parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) < 16) {
        alert('Sorry, your browser is not supported');
        this.props.history.push(`/about`);
        }

        var values = queryString.parse(this.props.location.search);		
        //alert("1");
        if (values.p) { 
            //alert("2");
            window.location.reload(); 
        }

    }
}